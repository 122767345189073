import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import firebase from 'firebase/app'
import 'firebase/firestore' // <- needed if using firestore
import 'firebase/auth'
import 'firebase/storage'
import { createStore, combineReducers } from 'redux'
import {
  ReactReduxFirebaseProvider,
  firebaseReducer,
} from 'react-redux-firebase'
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore'
import 'bootstrap/dist/css/bootstrap.min.css';
import './main.css';
import 'react-toggle/style.css';
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// Soft UI Dashboard React themes
import theme from "assets/theme";
import { Login } from './components/login/index';
import { Dashboard } from './components/dashboard';
import { AuthWrapper } from './components/authWrapper';
import { Users } from './components/users';
import { Customers } from './components/customers';
import { Jobs } from './components/jobs';
import * as serviceWorker from './serviceWorker';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { NewJob } from './components/jobs/newJob';
import { ViewJob } from './components/jobs/viewJob';
import { MyDocument } from './components/jobs/newJob/jobComponents/pdfGen';
import { CustomerView } from './components/customers/view';
import { Settings } from './components/settings';
import { jobsReducer } from './reducers/jobs';
import { CalendarView } from './components/calendar';
import { QuotesRequest } from 'components/quotesRequested';
import { DBHooks } from 'components/dbHooks';



const fbConfig = {
  apiKey: "AIzaSyCGTxVF8kBemMM010A_IcBXbffbc7ViYow",
  authDomain: "onpointdashboard.firebaseapp.com",
  databaseURL: "https://onpointdashboard.firebaseio.com",
  projectId: "onpointdashboard",
  storageBucket: "onpointdashboard.appspot.com",
  messagingSenderId: "765805828871",
  appId: "1:765805828871:web:387cf6c573ac6a279043a2"
}

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true
}

// Initialize firebase instance
firebase.initializeApp(fbConfig)

// Initialize other services on firebase instance
firebase.firestore() // <- needed if using firestore

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  jobsReducer: jobsReducer,
})

// Create store with reducers and initial state
const initialState = {}
const store = createStore(rootReducer, initialState)

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}


ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <DBHooks />
        <Router>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route path="/dashboard">
              <AuthWrapper Component={Dashboard} />
            </Route>
            <Route path="/users">
              <AuthWrapper Component={Users} />
            </Route>
            <Route exact path="/customers/view/:id">
              <AuthWrapper Component={CustomerView} />
            </Route>
            <Route path="/customers">
              <AuthWrapper Component={Customers} />
            </Route>
            <Route exact path="/jobs/view/:id">
              <AuthWrapper Component={ViewJob} />
            </Route>
            <Route exact path="/jobs/">
              <AuthWrapper Component={Jobs} />
            </Route>
            <Route exact path="/jobs/:location/:status">
              <AuthWrapper Component={Jobs} />
            </Route>
            <Route exact path="/jobs/tech/:id/:name">
              <AuthWrapper Component={Jobs} />
            </Route>
            <Route exact path="/jobs/new">
              <AuthWrapper Component={NewJob} />
            </Route>
            <Route exact path="/print">
              <AuthWrapper Component={MyDocument} />
            </Route>
            <Route path="/settings">
              <AuthWrapper Component={Settings} />
            </Route>
            <Route path="/calendar">
              <AuthWrapper Component={CalendarView} />
            </Route>
            <Route path="/qrequest">
              <AuthWrapper Component={QuotesRequest} />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
