import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export const ActionWarning = ({ text, actionFunction, open, closeFunction, loading }) => {
  
    return (
        <Dialog
          open={open}
          onClose={()=> closeFunction()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Warning
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {text}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} onClick={()=> closeFunction()}>Cancel</Button>
            <Button disabled={loading} onClick={actionFunction} autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
    );
}