import React, { useState, useEffect, } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Label, Input, Spinner } from 'reactstrap';
import FullCalendar from '@fullcalendar/react';
import { CustomEvent } from './components/events';
import _ from 'lodash';
import { CalComponent } from './components/calendar';
import Alert from 'reactstrap/lib/Alert';
import moment from 'moment';
import { useFirestore } from 'react-redux-firebase';
import toast, { Toaster } from 'react-hot-toast';
import './style.css';

export const CalendarView = () => {
    const firestore = useFirestore();
    const { user, allJobs } = useSelector(state => state.firestore.data);
    const { requesting } = useSelector(state => state.firestore.status);
    const [selectedBranch, setSelectedBranch] = useState(0);
    const [events, setEvents] = useState([])
    const [jobList, setJobList] = useState([]);
    const [jobListPayment, setJobListPayment] = useState([]);
    const [jobListComplete, setJobListComplete] = useState([]);
    const [externalEvent, setExternalEvent] = useState([]);

    useEffect(() => {
        if (!_.isEmpty(allJobs)) {
            let jobsOne = [];
            let jobsTwo = [];
            let jobsThree = [];
            let jobsFour = [];
            let jobsFive = [];
            let jobsSix = [];
            _.each(allJobs, job => {
                if (!job) return null; // fix for deleted jobs.
                if (Number.isNaN(job.dateOfInstall) && job.branch === selectedBranch) {
                    jobsFour.push(job)
                } else if (job.status === 'in-progress' && job.branch === selectedBranch) {
                    jobsOne.push(job);
                } else if (job.status === 'awaiting payment' && job.branch === selectedBranch) {
                    jobsTwo.push(job);
                }
                else if (job.status === 'complete' && job.branch === selectedBranch) {
                    jobsThree.push(job);
                }
                else if (job.status === 'on-hold' && job.branch === selectedBranch) {
                    jobsOne.push(job);
                }
                else if (job.status === 'cancelled' && job.branch === selectedBranch) {
                    jobsOne.push(job);
                }
            });
            setJobList([...jobsOne]);
            setJobListPayment([...jobsTwo]);
            setJobListComplete([...jobsThree]);
            setExternalEvent([...jobsFour])
        }

    }, [selectedBranch, allJobs])
    useEffect(() => {
        if (user && user.hasOwnProperty('branches')) {
            setSelectedBranch(user.branches[0])
        }
    }, [user]);

    const colourCheck = (jobType) => {
        if (jobType === 'Auto Glass') {
            return {
                text: 'black',
                color: '#049bff'
            }
        }
        if (jobType === 'Flatglass') {
            return {
                text: 'black',
                color: '#ffbc02'
            }
        }
        if (_.includes(jobType, 'Tinting')) {
            return {
                text: 'black',
                color: '#e6001f'
            }
        }
        return {
            text: 'black',
            color: '#e6001f'
        }
    }

    useEffect(() => {
        //jobList,jobListPayment)
        let merged = _.merge(_.keyBy(jobList, 'id'), _.keyBy(jobListComplete, 'id'));
        let newEventsList = [];
        _.each(merged, ev => {
            let newObj = {
                ...ev,
                calendar: {
                    start: new Date(ev.dateOfInstall * 1000),
                    allDay: true,
                    title: `${ev.jobId} - ${ev.jobType}`,
                    color: colourCheck(ev.jobType).color,
                    textColor: colourCheck(ev.jobType).text,
                    id: ev.id
                }
            }
            newEventsList.push(newObj);
        })
        setEvents(newEventsList);

    }, [jobList, jobListPayment, jobListComplete]);


    const loadingSpinner = () => {
        return <div className='loading-wrapper'><Spinner color="danger" /><p>Loading...</p></div>
    }

    if (_.isEmpty(requesting) || !user || !user.hasOwnProperty('branches') || requesting.jobList || requesting.jobListPayment) {
        return loadingSpinner()
    }

    const saveSettings = async (publicId, start) => {
        const time = moment(start);
        try {
            const job = await firestore.collection('jobs').doc(publicId).get();
            await job.ref.update({
                dateOfInstall: time.unix(),
            });
        } catch (err) {
            console.log(err);
        }
    }

    const onDropExternal = async (event) => {
        console.log(event)
        const { event: { _def: { publicId }, _instance: { range: { start } } } } = event;
        toast.promise(
            saveSettings(publicId, start),
            {
                loading: 'Saving... calendar update',
                success: <b>Calendar saved!</b>,
                error: <b>Could not save.</b>,
            }
        );


    }

    const onDrop = async (event) => {
        if(event.hasOwnProperty('draggedEl')) {
            const date = event.date;
            const toMap = _.map(event.draggedEl);
            const id = toMap[1].data;
            console.log(id, date);
            toast.promise(
                saveSettings(id, date),
                {
                    loading: 'Saving... calendar update',
                    success: <b>Calendar saved!</b>,
                    error: <b>Could not save.</b>,
                }
            );
        } else {
            const { event: { _def: { publicId }, _instance: { range: { start } } } } = event;
            console.log(publicId, start)
            toast.promise(
                saveSettings(publicId, start),
                {
                    loading: 'Saving... calendar update',
                    success: <b>Calendar saved!</b>,
                    error: <b>Could not save.</b>,
                }
            );
        }
   


    }

    /*
     <div id="external-events">
                    {_.map(jobList, (ev, index) => {
                        return <CustomEvent event={ev} index={index} key={index} />
                    })}
                    </div>
    */

    return (
        <Container fluid={true}>
            <Container>
              
            </Container>
            <Row>
                <Col >
                    <Label>Select branch</Label>
                    <Input type='select' onChange={(e) => setSelectedBranch(e.target.value)} value={selectedBranch}>
                        {_.map(user.branches, (branch, index) => {
                            return <option key={index}>{branch}</option>
                        })}
                    </Input>
                    <div className='external-events-wrapper' id="external-events">
                        {_.map(externalEvent, (ev, index) => {
                            return <CustomEvent event={ev} index={index} key={index} />
                        })}
                    </div>
                </Col>
                <Col xl={10} lg={10} md={10}>
                    <CalComponent onDrop={onDrop} events={events} />
                </Col>
            </Row>
        </Container>
    )
}