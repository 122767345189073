import React from 'react';
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

export const JobCard = ({ tech, count }) => {

    return (
        <DefaultInfoCard
            icon={"account_balance"}
            title={tech}
            description=""
            value={count}
        />
    )
}


