import React from 'react';
import './style.css';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { AdminMenus } from './admin';
import { WorkshopMenus } from './workshop';


export const Navigation = () => {

    const history = useHistory();
    const { user } = useSelector(state => state.firestore.data);

    if (!user) {
        return null
    }



    const { userType: { isAdministrator } } = user;

    return (
        <div className='nav-main-wrapper'>
            <div className='nav-links-wrapper'>
                {isAdministrator ? <AdminMenus /> : <WorkshopMenus />}
            </div>
        </div>
    );
}
