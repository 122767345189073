import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { Navigation } from '../nav';
import { TopBar } from '../nav/topBar';
import { Toaster } from 'react-hot-toast';
import _ from 'lodash'


export const AuthWrapper = (props) => {
  const { Component } = props;
  const history = useHistory();
  const data = useSelector(state => state.firestore.data)
  const thing = useSelector(state => state)
  const authData = useSelector(state => state.firebase.auth);
  const { isLoaded, isEmpty } = authData;

  useEffect(() => {
    const { isLoaded, isEmpty } = authData;
    if(isLoaded && isEmpty) {
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authData])

  useFirestoreConnect([
    {
        collection: 'users',
        storeAs: 'userList'
    },
    {
        collection: 'settings',
        storeAs: 'settingsList'
    },
    {
        collection: 'users',
        doc: authData && authData.hasOwnProperty('uid') ? authData.uid : 'null',
        storeAs: 'user'
    },
    {
        collection: 'jobs',
        where: [['status', '==', 'in-progress'], ['technicans', 'array-contains', authData && authData.hasOwnProperty('uid') ? authData.uid : 'null']],
        storeAs: 'techJobList'
    },
    {
        collection: 'jobs',
        storeAs: 'allJobs',
    },
    {
        collection: 'customers',
        storeAs: 'customerList'
    },
    {
        collection: 'quoteRequest',
        storeAs: 'quoteRequests'
    },
  ])
 
  
  if(!isLoaded || !data.hasOwnProperty('settingsList') || !data.hasOwnProperty('user')) {
    return null // loading screen
  }

  if(data.user && !data.user.hasOwnProperty('userType')) {
    return null // loading
  }
  if(data.user.userType.isAdministrator && !data.hasOwnProperty('allJobs')) {
    return null // loading screen
  }
  else if(isLoaded && !isEmpty && !_.isEmpty(data)) {
    return (
      <Container fluid={true} className='no-padding'>
         <Toaster />
        <TopBar />
        <Row>
          <Col className='nav-col no-padding' xsm={1} sm={1} md={2} lg={2} xl={2}>
            <Navigation />
          </Col>
          <Col className='content-col no-padding'>
              <Component />
          </Col>
        </Row>
      </Container>
      )
  } else {
    return null
  }
}
