import React, { useState } from 'react';
import { Row, Col, Input } from 'reactstrap';
import { MdDelete, MdEdit, MdCheck } from 'react-icons/md';

export const LineItem = ({ item, deleteJobDescription, updateItemValue, updateItemDescription, index, user }) => {
    const [isEditable, setIsEditable] = useState(false);

    const renderIcon = () => {
        return !isEditable ? <MdEdit className='edit-icon' size={24} onClick={(e) => setIsEditable(!isEditable)} /> : <MdCheck className='check-icon' size={24} onClick={(e) => setIsEditable(!isEditable)} />
    }

    return (
        <Row className='job-item-row'>
            <Col lg={8} md={8} sm={8}>
                <Input type='textarea' value={item.description} onChange={(e) => updateItemDescription(e.target.value, index)} disabled={!user.userType.isAdministrator || !isEditable} />
            </Col>
            <Col>
                <Input type='number' value={parseFloat(item.jobAmount)} disabled={!user.userType.isAdministrator || !isEditable } onChange={(e) => updateItemValue(parseFloat(e.target.value), index)} />
            </Col>
            <Col lg={1} md={1} sm={1} className='delete-col'>
                {user.userType.isAdministrator ? renderIcon() : null}
            </Col>
            <Col lg={1} md={1} sm={1} className='delete-col'>
                {user.userType.isAdministrator ? <MdDelete className='delete-icon' size={24} onClick={(e) => deleteJobDescription(item.key)} /> : null}
            </Col>
        </Row>
    )
}