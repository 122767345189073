import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Input, Label, Table, Modal, ModalBody, ModalHeader, Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { useHistory, useParams } from 'react-router-dom';
import { MainButton } from '../../modules/button';
import _ from 'lodash';
import moment from 'moment';
import { UserMenu } from '../../modules/table/menuOptions';
import { NoResults } from '../../modules/table/noResults';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { AddressLookup } from '../../modules/addressLookup/index';
import { MdRemoveCircleOutline } from 'react-icons/md';
import { LoadingTable } from '../../modules/table/loading';


export const CustomerView = () => {
  const firestore = useFirestore();
  const params = useParams();
  const { user, settingsList, allJobs } = useSelector(state => state.firestore.data);
  const [customerJobs, setCustomerJobs] = useState([])
  const data = useSelector(state => state.firestore.data);
  const [custObj, setCustObj] = useState(null);
  const [loading, setLoading] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const history = useHistory();
  const [addressSearchResults, setAddressSearchResults] = useState([]);
  const [lockedAddress, setLockedAddress] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    if (params) {
      const getcust = async () => {
        await firestore.get({
          collection: 'customers',
          doc: params.id,
          storeAs: `customer_${params.id}`
        })
      }
      getcust()
    }
  }, [params])

  useEffect(() => {
    if (custObj) {
      const { address: { physical } } = custObj;
      const fetchResults = async () => {
        if (!lockedAddress) {
          const results = await AddressLookup(physical);
          setAddressSearchResults(results);
        }
      }
      fetchResults()
    }

  }, [custObj ? custObj.address.physical : []]);



  useEffect(() => {
    if (data[`customer_${params.id}`]) {
      // patch to ensure every cust has insurance details field
      let cust = data[`customer_${params.id}`];
      if (!cust.hasOwnProperty('insuranceDetails')) {
        cust = {
          insuranceDetails: {
            policyNumber: '',
            companyName: ''
          },
          ...data[`customer_${params.id}`]
        }
      }
      setCustObj(cust);
    }
  }, [data[`customer_${params.id}`]])

  useEffect(() => {
    let custJobs = _.filter(allJobs, (list) => {
      return list.customerDetails.customerId === params.id;
    })
    if (allJobs) {
      setTableLoading(false);
      setCustomerJobs(custJobs)
    }

  }, [allJobs])

  if (!data[`customer_${params.id}`] || !custObj) {
    return null
  }

  const tableHeader = () => {
    return (
      <thead className='table-header'>
        <tr>
          <th>Date Logged</th>
          <th>Job ID</th>
          <th>Job Type</th>
          <th>Job Location</th>
          <th>Location</th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
    )
  }

  const renderJobStatus = (status) => {
    if (status === 'in-progress') {
      return <td><p className='in-prog'>IN-PROGRESS</p></td>
    }
    if (status === 'awaiting payment') {
      return <td><p className='in-prog red'>INVOICED</p></td>
    }
    if (status === 'complete') {
      return <td><p className='in-prog green'>COMPLETE</p></td>
    }
  }


  const renderJobLocation = (isOnsite) => {
    if (isOnsite) {
      return <p className='commercial-p'>Onsite</p>
    } else if (_.isEmpty(isOnsite)) {
      return <p>N.A</p>
    } else if (!isOnsite) {
      return <p>Workshop</p>
    }
  }

  const renderTableBody = () => {
    if (!_.isEmpty(customerJobs)) {
      let completeList = _.sortBy(customerJobs, (list) => {
        return list.audit.createdAt;
      })
      completeList.reverse();
      return (
        <tbody>
          {_.map(completeList, (job, index) => {
            const { isOnsite, status, jobType, branch, audit: { createdAt }, jobId } = job
            return (
              <tr key={index}>
                <td>{moment.unix(createdAt).format('DD/MM/YYYY')}</td>
                <td>{jobId}</td>
                <td>{jobType}</td>
                <td className='center-job-loc'>{renderJobLocation(isOnsite)}</td>
                <td>{branch}</td>
                {renderJobStatus(status)}
                <UserMenu user={job} setEditOpen={null} setEditUser={null} menuType={'jobs'} />
              </tr>
            )
          })}
        </tbody>
      )
    } else {
      return null
    }
  }

  const updateCustomer = async () => {
    setLoading(true);
    const doc = await firestore.collection('customers').doc(params.id).get();
    const date = moment().unix();
    await doc.ref.update({
      ...custObj,
      audit: {
        ...custObj.audit,
        lastUpdatedByName: user.fullName,
        lastUpdatedById: user.id,
        lastUpdatedAt: date,
      }
    })
    setLoading(false);
  }

  const deleteCustomer = () => {
    firestore.delete({
      collection: 'customers',
      doc: custObj.id,
    })
    history.push('/customers');
  }

  const addCustomer = () => {
    return (
      <Modal size='lg' isOpen={warningModal} toggle={() => setWarningModal(!warningModal)}>
        <ModalHeader toggle={() => setWarningModal(!warningModal)}>
          Warning!
        </ModalHeader>
        <ModalBody>
          <p>You are about to delete customer <strong>{custObj.fullName}</strong></p>
          <p>This customer has {_.size(customerJobs)} previous job(s). Deleting the customer <strong>will not remove</strong> these jobs.</p>
        </ModalBody>
        <ModalFooter>
          <Button color='danger' onClick={() => deleteCustomer()}>Yes, Delete</Button>
          <Button onClick={() => setWarningModal(!warningModal)} >Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }

  const setAddress = (match) => {
    const { place_name, geometry: { coordinates } } = match;
    setCustObj({ ...custObj, address: { physical: place_name, geo: { long: coordinates[0], lat: coordinates[1] } } });
    setLockedAddress(true);
  }

  const autoCompleteAddress = () => {
    if (_.isEmpty(addressSearchResults.features)) {
      return null
    } else {
      return (
        <div className='address-auto-complete'>
          {_.map(addressSearchResults.features, (match, index) => {
            return <p className='cursor' key={index} onClick={() => setAddress(match)}>{match.place_name}</p>
          })}
        </div>
      )
    }
  }

  const renderInsuranceInfo = () => {
    const { insuranceDetails: { insuranceCompany, policyNumber } } = custObj;
    return (
      <div>
        <Row>
          <Col>
            <p>Company:</p>
          </Col>
          <Col>
            <p>{insuranceCompany}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>Policy Number / Claim #:</p>
          </Col>
          <Col>
            <p>{policyNumber}</p>
          </Col>
        </Row>
      </div>
    )
  }



  return (
    <Container fluid={true}>
      <Row>
        <Col>
          <Label>Full name</Label>
          <Input value={custObj.fullName} type='text' onChange={(e) => setCustObj({ ...custObj, fullName: e.target.value })} />
        </Col>
        <Col>
          <Label>Address</Label>
          <Input disabled={lockedAddress} value={custObj.address.physical} type='text' onChange={(e) => setCustObj({ ...custObj, address: { ...custObj.address, physical: e.target.value } })} />
          {lockedAddress ? <MdRemoveCircleOutline className='remove-addy view' size={24} onClick={() => {
            setCustObj({ ...custObj, address: { physical: '', geo: { long: '', lat: '' } } })
            setAddressSearchResults([])
            setLockedAddress(false);
          }} /> : null}
          {!_.isEmpty(addressSearchResults) && !lockedAddress ? autoCompleteAddress() : null}
        </Col>
      </Row>
      <Row>
        <Col>
          <Label>Cellphone</Label>
          <Input value={custObj.mobileContact} type='number' onChange={(e) => setCustObj({ ...custObj, mobileContact: e.target.value })} />
        </Col>
        <Col>
          <Label>Home phone</Label>
          <Input value={custObj.homeContact} type='number' onChange={(e) => setCustObj({ ...custObj, homeContact: e.target.value })} />
        </Col>
        <Col>
          <Label>Branch</Label>
          <Input value={custObj.location} type='select' onChange={(e) => setCustObj({ ...custObj, location: e.target.value })}>
            {_.map(settingsList ? settingsList.settings.locations : [], (branch, index) => {
              return <option key={index} value={branch}>{branch}</option>
            })}
          </Input>
        </Col>
      </Row>
      <Row>
        <Col>
          <Label>Email</Label>
          <Input value={custObj.email} type='email' onChange={(e) => setCustObj({ ...custObj, email: e.target.value })} />
        </Col>
        <Col>
          <Row>
            <Col>
              <Label>Insurance Name</Label>
              <Input value={custObj.insuranceDetails.insuranceCompany} onChange={(e) => setCustObj({ ...custObj, insuranceDetails: { ...custObj.insuranceDetails, insuranceCompany: e.target.value } })} />
            </Col>

          </Row>
          <Row>
            <Col>
              <Label>Policy Number / Claim Number</Label>
              <Input value={custObj.insuranceDetails.policyNumber} onChange={(e) => setCustObj({ ...custObj, insuranceDetails: { ...custObj.insuranceDetails, policyNumber: e.target.value } })} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Label className='w-100'>Delete customer</Label>
          <Button onClick={() => setWarningModal(!warningModal)}>Delete</Button>
        </Col>
      </Row>
      <div className='update-btn-wrapper'>
        <MainButton text={'Update'} loading={loading} disabled={loading} className='update-btn mt-5' clickAction={updateCustomer} />
      </div>

      <Label>Customer history</Label>
      <Table>
        {tableHeader()}
        {renderTableBody()}
      </Table>
      {tableLoading ? <LoadingTable /> : null}
      {_.isEmpty(customerJobs) && !tableLoading ? <NoResults /> : null}
      {addCustomer()}
    </Container>
  )
}