import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useFirestoreConnect, useFirestore, useFirebase, firestoreReducer, } from 'react-redux-firebase';
import { actionTypes } from 'redux-firestore';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer'

export const DBHooks = () => {
    const history = useHistory();
    const dispatch = useDispatch()
    const firebase = useFirebase();
    const firestore = useFirestore()
    const authData = useSelector(state => state.firebase.auth);
    const { data: { user } } = useSelector(state => state.firestore)
    const { isLoaded, isEmpty } = authData;
    const [hook1Loaded, setHook1Loaded] = useState(false)
    const [hook2Loaded, setHook2Loaded] = useState(false)
    const [remaining, setRemaining] = useState(0)

    const onIdle = async () => {
        if (user) {
            dispatch({ type: actionTypes.CLEAR_DATA, preserve: { data: ['userList', 'settingsList', 'techJobList', 'allJobs', 'customerList', 'quoteRequests'] } })
            await firebase.logout()
        }
    }

    const onActive = () => {
        //meh
    }

    const onAction = () => {
        //meh
    }

    const { getRemainingTime } = useIdleTimer({
        onIdle,
        onActive,
        onAction,
        timeout: 27000_00,
        throttle: 500
    })

 
/*     useEffect(() => {
        console.log(hook1Loaded)
        if (authData && !isEmpty && isLoaded) {
            const setListerners = async () => {
                await firestore.setListeners([

                    {
                        collection: 'users',
                        storeAs: 'userList'
                    },
                    {
                        collection: 'settings',
                        storeAs: 'settingsList'
                    },
                    {
                        collection: 'users',
                        doc: authData.uid,
                        storeAs: 'user'
                    },
                    {
                        collection: 'jobs',
                        where: [['status', '==', 'in-progress'], ['technicans', 'array-contains', authData.uid]],
                        storeAs: 'techJobList'
                    }
                ])
                setHook1Loaded(true)
            }
            setListerners()
        }
        return () => {
            firestore.unsetListeners([{
                collection: 'users',
            },
            {
                collection: 'settings',
            },
            {
                collection: 'jobs',
            }
            ])
            console.log("cleaned up");
        };
    }, [authData])

    useEffect(() => {

        if (user && user.hasOwnProperty('userType') && user.userType.isAdministrator) {    
            const setAdminListerners = async () => {
                await firestore.setListeners([{
                    collection: 'jobs',
                    storeAs: 'allJobs',
                },
                {
                    collection: 'customers',
                    storeAs: 'customerList'
                },
                {
                    collection: 'quoteRequest',
                    storeAs: 'quoteRequests'
                },
                ])
                setHook2Loaded(true)
            }
            setAdminListerners()

        }
        return () => {
            firestore.unsetListeners([{
                collection: 'jobs',

            },
            {
                collection: 'customers',

            },
            {
                collection: 'quoteRequest',

            },
            ])
            console.log("cleaned up admin");
        };
    }, [user]) */

    return null
}