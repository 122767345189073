import React, { useState, useEffect } from 'react';
import { Label, Row, Col, Input, } from 'reactstrap';
import _ from 'lodash';

export const CarDetails = ({updateFunc, newJob, update, user}) => {

  if(update) {
    return(
      <div>
        <Row>
            <Col>
            <Label>Make</Label>
            <Input className='job-input' value={newJob.carDetails.make} disabled={user.userType.isAdministrator ? false : true} onChange={(e) => updateFunc({...newJob, carDetails: {...newJob.carDetails, make: e.target.value}})}/>
            </Col>
            <Col>
            <Label>Model</Label>
            <Input className='job-input' value={newJob.carDetails.model} disabled={user.userType.isAdministrator ? false : true} onChange={(e) => updateFunc({...newJob, carDetails: {...newJob.carDetails, model: e.target.value}})}/>
            </Col>
        </Row>
        <Row>
            <Col>
            <Label>Rego</Label>
            <Input className='job-input' value={newJob.carDetails.rego} disabled={user.userType.isAdministrator ? false : true} onChange={(e) => updateFunc({...newJob, carDetails: {...newJob.carDetails, rego: e.target.value}})}/>
            </Col>
            <Col>
            <Label>Year</Label>
            <Input className='job-input' value={newJob.carDetails.year} disabled={user.userType.isAdministrator ? false : true} onChange={(e) => updateFunc({...newJob, carDetails: {...newJob.carDetails, year: e.target.value}})}/>
            </Col>
        </Row>
    </div>
    )
  }
 
  return (
      <div>
        <Row>
            <Col>
            <Label>Make</Label>
            <Input className='job-input' onChange={(e) => updateFunc({...newJob, carDetails: {...newJob.carDetails, make: e.target.value}})}/>
            </Col>
            <Col>
            <Label>Model</Label>
            <Input className='job-input' onChange={(e) => updateFunc({...newJob, carDetails: {...newJob.carDetails, model: e.target.value}})}/>
            </Col>
        </Row>
        <Row>
            <Col>
            <Label>Rego</Label>
            <Input className='job-input' onChange={(e) => updateFunc({...newJob, carDetails: {...newJob.carDetails, rego: e.target.value}})}/>
            </Col>
            <Col>
            <Label>Year</Label>
            <Input className='job-input' onChange={(e) => updateFunc({...newJob, carDetails: {...newJob.carDetails, year: e.target.value}})}/>
            </Col>
        </Row>
      </div>
   
  );
}

