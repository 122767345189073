import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Container, Table, Row, Col, Button, Input, Label, Card, CardBody, CardTitle, CardFooter, FormText } from 'reactstrap';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

export const WorkshopDashboard = () => {

  const { user, techJobList, } = useSelector(state => state.firestore.data);
  const history = useHistory();

  const renderCarDetails = ({ make, model, rego, year }) => {
    return (
      <div>
        <Label className='mt-1'>Vehicle details</Label>
        <Row>
          <Col>
            {make}
          </Col>
          <Col>
            {model}
          </Col>
        </Row>
        <Row>
          <Col>
            {rego}
          </Col>
          <Col>
            {year}
          </Col>
        </Row>
      </div>

    )
  }

  const rendderCustAddress = (customerAddress) => {
    return (
      <div>
        <Label className='mt-1'>Address</Label>
        <Row>
          <Col>
            {customerAddress}
          </Col>
        </Row>
      </div>

    )
  }

  const renderJobCard = () => {
    return _.map(techJobList, (job, index) => {
      const { jobType, id, carDetails, customerDetails: { customerName, customerAddress } } = job;
      return (
        <Card key={index} className='job-card' >
          <Label className='card-tile'>{jobType}</Label>
          <CardBody>
            <Label>Customer</Label>
            <Row><Col>{customerName}</Col></Row>
            {!_.isEmpty(carDetails) ? renderCarDetails(carDetails) : null}
            {_.isEmpty(carDetails) ? rendderCustAddress(customerAddress) : null}
          </CardBody>
          <CardFooter className='cursor-hover'>
            <FormText className='text-center' onClick={(e) => history.push(`/jobs/view/${id}`)}>Click for more info</FormText>
          </CardFooter>
        </Card>
      )
    })
  }
  return (
    <Container fluid={true} className={'pt-2'}>
      <h4 className='text-center'>Total open jobs {_.size(techJobList)}</h4>
      <div className='job-card-row'>
        {renderJobCard()}
      </div>

    </Container>
  );
}