import React from 'react';
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";

export const Chart = ({open, completed, onHold, quotes}) => {

    return (
        <VerticalBarChart
            title="Job Status"
            chart={{
                labels: ["Quotes","Open Jobs", "Completed Jobs", "On-Hold"],
                datasets: [{
                    label: "Jobs",
                    color: "dark",
                    data: [quotes, open, completed, onHold],
                }],
            }}
        />
    )
}


