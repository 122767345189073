import React from 'react';
import NoResultsImg from '../../../../images/custom/no_results.svg';

export const NoResults = () => {
    return(
    <div className='no-results-table'>
        <img alt='decrotive' src={NoResultsImg}/>
        <p>Sorry no results found!</p>
    </div>
    )
}