import React, { useState } from 'react';
import { FiMoreVertical } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import _ from 'lodash'


export const UserMenu = ({user ,setEditOpen, setEditUser, setChangePasswordOpen, menuType, deleteUserFunc, setJobInfo, showDeleteJobWarning, search}) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch();

  const openEdit = () => {
    setEditOpen(true);
    setEditUser(user)
  }

  const openChangePassword = () => {
    setChangePasswordOpen(true)
    setEditUser(user)
  }

  const deleteUser = () => {
    setEditUser(user)
    deleteUserFunc();
  }

  const deleteJob = () => {
    setJobInfo(user);
    showDeleteJobWarning();
  }

  const navJobs = () => {
    if(!_.isEmpty(search)) {
      dispatch({
        type: 'SET',
        payload: search,
      })
      history.push(`/jobs/view/${user.id}`)
    } else {
     /* dispatch({
        type: 'REMOVE',
        payload: search,
      }) */
      history.push(`/jobs/view/${user.id}`)
    }
  }

  const renderMenuOptions = () => {
    if(menuType === 'users') {
        return (
        <div className='user-menu-wrapper' onMouseLeave={(e) => setIsOpen(!isOpen)}>
          <p onClick={(e) => openEdit()}>Edit</p>
          <p onClick={(e) => openChangePassword()}>Change password</p>
          <p onClick={(e) => deleteUser()}>Delete</p>
        </div>
        )
    } else if (menuType === 'customers') {
        return(
        <div className='user-menu-wrapper' onMouseLeave={(e) => setIsOpen(!isOpen)}>
          <p onClick={(e) => history.push(`/customers/view/${user.id}`)}>View / Edit</p>
        </div>
        )
        
    } else if (menuType === 'jobs') {
        return(
        <div className='user-menu-wrapper' onMouseLeave={(e) => setIsOpen(!isOpen)}>
          <p onClick={(e) => navJobs()}>View / edit</p>
          {user.status !== 'complete' ? <p onClick={(e) => deleteJob()}>Delete</p> : null}
        </div>
        )
    }
    else {
        return null;
    }
  }

    return(
      <td className='menu-col'><FiMoreVertical size={24} onClick={(e) => setIsOpen(!isOpen)} className='menu-icon'/>
        {isOpen ? renderMenuOptions() : null}
      </td>
    )
}