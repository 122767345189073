import React from 'react';
import { Button } from 'reactstrap';
import { MetroSpinner } from "react-spinners-kit";


export const MainButton = (props) => {
  const { className, loading, disabled, text, clickAction, icon } = props;

  return (
        <Button className={className} disabled={disabled} onClick={(e) => clickAction ? clickAction() : null}>
        {loading ? <MetroSpinner className='btn-loader'/> : <div>{icon}{text}</div>}</Button>
  );
}