import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Label, Row, Col, Input, Button, Modal, ModalHeader, ModalBody,ModalFooter } from 'reactstrap';
import _ from 'lodash';
import { MdRemoveCircleOutline } from 'react-icons/md';
import { useFirestore } from 'react-redux-firebase';
import { MainButton } from '../modules/button';


export const Settings = () => {

  const firestore = useFirestore();  
  const { settings, allJobs } = useSelector(state => state.firestore.data);
  const [newBranch, setNewBranch] = useState('');
  const [branchToDelete, setBranchToDelete] = useState('');
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const removeBranch = async () => {
    setLoading(true); 
    const docRef = await firestore.collection('settings').doc('settings').get();
    const locations = _.filter(settings.locations, (location) => {
        return location !== branchToDelete;
    })
    docRef.ref.update({
        locations: [...locations],
    });
    setLoading(false);
    setBranchToDelete('');
    setShowWarningModal(!showWarningModal)
  }

  const addBranch = async () => {
    if(!_.isEmpty(newBranch)) {
       setLoading(true); 
       const docRef = await firestore.collection('settings').doc('settings').get();
       docRef.ref.update({
           locations: [...settings.locations, newBranch],
       });
       setLoading(false);
       setNewBranch('');
    }
  }

  const deleteBranchModal = () => {
    return(
      <Modal size='lg' isOpen={showWarningModal} toggle={()=>setShowWarningModal(!showWarningModal)}>
        <ModalHeader toggle={()=>setShowWarningModal(!showWarningModal)()}>
          Warning!
        </ModalHeader>
        <ModalBody>
          <p>Are you sure you want to delete branch: <strong>{branchToDelete}</strong></p>
        </ModalBody>
        <ModalFooter>
          <Button color='danger' disabled={loading} onClick={()=>removeBranch()}>Yes, Delete</Button>
          <Button disabled={loading} onClick={()=>setShowWarningModal(!showWarningModal)} >Cancel</Button>
        </ModalFooter>
        </Modal>
    )
  }
 
  return (
    <Container fluid={true}>
      <Container className='settings-wrapper'>
        <h1>Settings</h1>
        <Row>
            <Col>
              <Label>Add new branch</Label>
              <Input type='text' value={newBranch} className='default-input' onChange={(e) => setNewBranch(e.target.value)} />
            </Col>
            <Col className='pt-3'>
               {_.map(settings ? settings.locations : [], (branch, index) => {
                  return <div key={index}>{branch}<MdRemoveCircleOutline onClick={() => { setShowWarningModal(true) 
                  setBranchToDelete(branch)}} className='remove-icon'/></div>
               })}
            </Col>
         </Row>
         <Button disabled={loading} onClick={() => addBranch()}>Add</Button>


      </Container>
      {deleteBranchModal()}
    </Container>
  );
}
