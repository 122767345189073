import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { MdHome } from 'react-icons/md';
import { useFirestoreConnect } from 'react-redux-firebase';

export const WorkshopMenus = () => {
    const { user, } = useSelector(state => state.firestore.data);
    const history = useHistory();
    const pathname = history.location.pathname;

    return (
        <div>
            <div className={_.includes(pathname, '/dashboard') ? 'nav-link active' : 'nav-link'} onClick={(e) => history.push('/dashboard')}>
                <MdHome size={24} color={_.includes(pathname, '/dashboard') ? '' : 'rgba(0,0,0,0.2)'} />
                <p>Dashboard</p>
            </div>
        </div>
    );
}