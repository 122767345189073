import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { MdHome, MdAssignmentTurnedIn, MdPeople, MdSettings, MdPersonAdd, MdEvent, MdList } from 'react-icons/md';


export const AdminMenus = () => {
    const history = useHistory();
    const pathname = history.location.pathname;

    return (
        <div>
            <div className={_.includes(pathname, '/dashboard') ? 'nav-link active' : 'nav-link'} onClick={(e) => history.push('/dashboard')}>
                <MdHome size={24} color={_.includes(pathname, '/dashboard') ? '' : 'rgba(0,0,0,0.2)'} />
                <p>Dashboard</p>
            </div>
            <div className={_.includes(pathname, '/qrequest') ? 'nav-link active' : 'nav-link'} onClick={(e) => history.push('/qrequest')}>
                <MdList size={24} color={_.includes(pathname, '/qrequest') ? '' : 'rgba(0,0,0,0.2)'} />
                <p>Quote Request</p>
            </div>
            <div className={_.includes(pathname, '/calendar') ? 'nav-link active' : 'nav-link'} onClick={(e) => history.push('/calendar')}>
                <MdEvent size={22} color={_.includes(pathname, '/calendar')
                    ? '' : 'rgba(0,0,0,0.2)'} />
                <p>Calendar</p>
            </div>
            <div className={_.includes(pathname, '/jobs') || _.includes(pathname, '/print') ? 'nav-link active' : 'nav-link'} onClick={(e) => history.push('/jobs')}>
                <MdAssignmentTurnedIn size={22} color={_.includes(pathname, '/jobs') || _.includes(pathname, '/print')
                    ? '' : 'rgba(0,0,0,0.2)'} />
                <p>Jobs</p>
            </div>
            <div className={_.includes(pathname, '/users') ? 'nav-link active' : 'nav-link'} onClick={(e) => history.push('/users')}>
                <MdPeople size={24} color={_.includes(pathname, '/users') ? '' : 'rgba(0,0,0,0.2)'} />
                <p>Users</p>
            </div>
            <div className={_.includes(pathname, '/customers') ? 'nav-link active' : 'nav-link'} onClick={(e) => history.push('/customers')}>
                <MdPersonAdd size={24} color={_.includes(pathname, '/customers') ? '' : 'rgba(0,0,0,0.2)'} />
                <p>Customers</p>
            </div>
            <div className={_.includes(pathname, '/settings') ? 'nav-link active' : 'nav-link'} onClick={(e) => history.push('/settings')}>
                <MdSettings size={24} color={_.includes(history.location, '/settings') ? '' : 'rgba(0,0,0,0.2)'} />
                <p>Settings</p>
            </div>
        </div>
    );
}