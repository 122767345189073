import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { UncontrolledTooltip, PopoverBody } from 'reactstrap';
import _ from 'lodash';
import './style.css';
import { useHistory } from 'react-router';

export const CalComponent = ({ onDrop, events }) => {
    const history = useHistory();
    const [eventList, setEventList] = useState([]);

    useEffect(() => {
        let copyEvents = [...events];
        let calEvents = [];
        _.each(copyEvents, ev => {
            calEvents.push(ev.calendar)
        })
        setEventList([...calEvents])
    }, [events]);


    const renderToolTip = (e) => {
        const makeId = 'a'+e.event._def.publicId;
        let eventData = {};
        _.each(events, ev =>{
            if(ev.id === e.event._def.publicId) {
                eventData = {
                    ...ev,
                }
            }
        });
        return (
            <div id={makeId}>
                {e.event._def.title}
                <UncontrolledTooltip target={makeId} placement='bottom'>
                    <div className='tool-t-body'>
                        <p className='tool-t-p'>{eventData.hasOwnProperty('customerDetails') ? eventData.customerDetails.customerName : null}</p>
                        <p className='tool-t-p'>Job: {eventData.status}</p>
                    </div>
                </UncontrolledTooltip>
            </div>
        )
    }
    return (

        <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridWeek"
            headerToolbar={{
                start: 'title', // will normally be on the left. if RTL, will be on the right
                center: 'dayGridMonth,dayGridWeek,dayGridDay',
                end: 'prev,next,today' // will normally be on the right. if RTL, will be on the left
              }}
            handleWindowResize={true}
            droppable={true}
            events={eventList}
            drop={onDrop}
            eventDrop={onDrop}
            editable={true}
            eventContent={(e) => renderToolTip(e)}
            eventClick={(e) => history.push(`jobs/view/${e.event._def.publicId}`)}
        />
    )
}