import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Input, Label, Card, CardBody, Spinner } from 'reactstrap';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { Chart } from './components/chart';
import { JobCard } from './components/jobCards';
import SuiBox from "components/SuiBox";
import SuiSelect from "components/SuiSelect";

export const AdminDashboard = () => {

    const history = useHistory();
    const { user, allJobs } = useSelector(state => state.firestore.data);
    const { requesting } = useSelector(state => state.firestore.status);
    const [selectedBranch, setSelectedBranch] = useState(user.branches[0]);
    const [techList, setTechList] = useState({});
    const [jobList, setJobList] = useState([]);
    const [jobListPayment, setJobListPayment] = useState([]);
    const [jobListComplete, setJobListComplete] = useState([]);
    const [jobsOnHold, setJobsOnHold] = useState([]);
    const [jobsQuotes, setJobsQuotes] = useState([])
    const [jobsToBeQuoted, setJobsTobeQuoted] = useState([])

    useEffect(() => {
        if (!_.isEmpty(allJobs)) {
            let jobsOne = [];
            let jobsTwo = [];
            let jobsThree = [];
            let jobsOnHold = [];
            let jobsQuotesList = [];
            let jobsToBeQuotesList = [];
            _.each(allJobs, job => {
                // stupid checks for deleted jobs
                if (!job) {
                    return;
                }
                if (!job.hasOwnProperty('status')) {
                    return;
                }
                if (job.status === 'in-progress' && job.branch === selectedBranch) {
                    jobsOne.push(job);
                } else if (job.status === 'awaiting payment' && job.branch === selectedBranch) {
                    jobsTwo.push(job);
                }
                else if (job.status === 'complete' && job.branch === selectedBranch) {
                    jobsThree.push(job);
                }
                else if (job.status === 'on-hold' && job.branch === selectedBranch) {
                    jobsOnHold.push(job);
                }
                else if (job.status === 'quote' && job.branch === selectedBranch) {
                    jobsQuotesList.push(job);
                }
                else if (job.status === 'to be quoted' && job.branch === selectedBranch) {
                    jobsToBeQuotesList.push(job);
                }
            });
            setJobList([...jobsOne]);
            setJobListPayment([...jobsTwo]);
            setJobListComplete([...jobsThree]);
            setJobsOnHold([...jobsOnHold]);
            setJobsQuotes([...jobsQuotesList])
            setJobsTobeQuoted([...jobsToBeQuotesList])
            
        }

    }, [selectedBranch, allJobs])

    useEffect(() => {
        let techMap = {};
        _.each(jobList, (job) => {
            _.each(job.techniciansName, (tech) => {
                if (techMap.hasOwnProperty(tech.name)) {
                    techMap = {
                        ...techMap,
                        [tech.name]: {
                            id: tech.id,
                            name: tech.name,
                            count: techMap[tech.name].hasOwnProperty('count') ? techMap[tech.name].count + 1 : 1,
                        }
                    }
                } else {
                    techMap = {
                        ...techMap,
                        [tech.name]: {
                            id: tech.id,
                            name: tech.name,
                            count: 1,
                        }
                    }
                }
            })
        });
        setTechList(techMap);
    }, [jobList])

    const loadingSpinner = () => {
        return <div className='loading-wrapper'><Spinner color="danger" /><p>Loading...</p></div>
    }
    //onClick={() => navToTechJobs(tech.id, tech.name)}
    /*
     <Card key={index} className='tech-card cursor-pointer-only' >
                    <CardBody>
                        <h6>{tech.name}</h6>
                        <p>{tech.count}</p>
                    </CardBody>
                </Card>*/

    const renderTechs = () => {
        const details = _.map(techList, (tech, index) => {
            return (
                <Col onClick={() => navToTechJobs(tech.id, tech.name)} className='cursor-pointer-only'>
                    <JobCard tech={tech.name} count={tech.count} key={index} />
                </Col>
            )
        })
        if (_.isEmpty(allJobs) || requesting.allJobs || requesting.allJobs) {
            return loadingSpinner()
        }
        if (_.isEmpty(details)) {
            return (
                <Col>
                    <p>There are currently no open jobs</p>
                </Col>
            )
        } else {
            return details
        }
    }

    const navToOpenJobs = (status) => {
        history.push(`jobs/${selectedBranch}/${status}`)
    }

    const navToTechJobs = (id, name) => {
        history.push(`jobs/tech/${id}/${name}`)
    }

    const renderJobCards = () => {
        if (_.isEmpty(requesting) || requesting.allJobs || requesting.allJobs) {
            return loadingSpinner()
        }
        return (
            <Row className='open-jobs-row'>
                <Col>
                    <Chart open={_.size(jobList)} completed={_.size(jobListComplete)} onHold={_.size(jobsOnHold)}
                    quotes={_.size(jobsQuotes)} />
                </Col>
            </Row>
        )
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Label>Select branch</Label>
                    {selectedBranch ? <SuiSelect
                        className='select-background'
                        onChange={(e) => setSelectedBranch(e.value)}
                        defaultValue={{ value: selectedBranch, label: selectedBranch }}
                        placeholder="Select Branch"
                        options={[..._.map(user.branches, (branch, index) => {
                            return { value: branch, label: branch }
                        })]}
                    /> : null}
                </Col>
                <Col></Col>
            </Row>
            {renderJobCards()}
            <Row className='mb-3 mt-3'>
                <Col className='cursor-pointer-only' onClick={() => navToOpenJobs('in-progress')}>
                    <SuiBox
                        color="white"
                        bgColor="warning"
                        variant="gradient"
                        borderRadius="lg"
                        shadow="lg"
                        opacity={1}
                        p={2}
                    >
                        View Open Jobs - ({_.size(jobList)})
                    </SuiBox>
                </Col>
                <Col className='cursor-pointer-only' onClick={() => navToOpenJobs('complete')}>
                    <SuiBox
                        color="white"
                        bgColor="error"
                        variant="gradient"
                        borderRadius="lg"
                        shadow="lg"
                        opacity={1}
                        p={2}
                    >
                        View Completed Jobs - ({_.size(jobListComplete)})
                    </SuiBox>
                </Col>

            </Row>
            <Row className='mb-3 mt-3'>
                <Col className='cursor-pointer-only' onClick={() => navToOpenJobs('awaiting payment')}>
                    <SuiBox
                        color="white"
                        bgColor="success"
                        variant="gradient"
                        borderRadius="lg"
                        shadow="lg"
                        opacity={1}
                        p={2}

                    >
                        View Invoiced Jobs - ({_.size(jobListPayment)})
                    </SuiBox>
                </Col>
                <Col className='cursor-pointer-only' onClick={() => navToOpenJobs('on-hold')}>
                    <SuiBox
                        color="white"
                        bgColor="secondary"
                        variant="gradient"
                        borderRadius="lg"
                        shadow="lg"
                        opacity={1}
                        p={2}

                    >
                        View On-Hold Jobs - ({_.size(jobsOnHold)})
                    </SuiBox>
                </Col>
            </Row>
            <Row className='mb-3 mt-3'>
                <Col className='cursor-pointer-only' onClick={() => navToOpenJobs('quote')}>
                    <SuiBox
                        color="white"
                        bgColor="warning"
                        variant="gradient"
                        borderRadius="lg"
                        shadow="lg"
                        opacity={1}
                        p={2}
                    >
                        View Quoted - ({_.size(jobsQuotes)})
                    </SuiBox>
                </Col>
                <Col className='cursor-pointer-only' onClick={() => navToOpenJobs('to be quoted')}>
                    <SuiBox
                        color="white"
                        bgColor="warning"
                        variant="gradient"
                        borderRadius="lg"
                        shadow="lg"
                        opacity={1}
                        p={2}
                    >
                        View To be Quoted - ({_.size(jobsToBeQuoted)})
                    </SuiBox>
                </Col>
            </Row>
            <Label>Technicans with open jobs</Label>
            <Row className='tech-row'>
                {renderTechs()}
            </Row>
        </Container>
    );
}