import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './style.css';
import { Popover, PopoverBody } from 'reactstrap';
import { FaChevronDown } from 'react-icons/fa';
import { useFirebase } from 'react-redux-firebase';
import { useHistory } from 'react-router-dom';
import Logo from '../../images/login/logo_white.png'
import UserIcon from '../../images/custom/user-icon.png'
import { Badge, Box, IconButton, Tooltip } from '@mui/material';
import { Notifications } from '@mui/icons-material/'
import { actionTypes } from 'redux-firestore';
import _ from 'lodash';

export const TopBar = () => {
    const dispatch = useDispatch();
    const firebase = useFirebase();
    const [showUserMenu, setShowUserMenu] = useState(false);
    const { user, quoteRequests } = useSelector(state => state.firestore.data);
    const history = useHistory();


    const navigate = (value) => {
        history.push(value)
        setShowUserMenu(!showUserMenu)
    }

    const renderWorkshopItems = () => {
        return (
            <div className='usermenu-nav'>
                <p onClick={(e) => navigate('/dashboard')}>Dashboard</p>
            </div>
        )
    }

    const logUserOut = async () => {
        dispatch({ type: actionTypes.CLEAR_DATA, preserve: { data: ['userList', 'settingsList', 'techJobList', 'allJobs', 'customerList', 'quoteRequests'] } })
        await firebase.logout()
    }

    console.log('nav')

    const userMenu = () => {
        return (
            <Popover
                placement={"bottom"}
                isOpen={showUserMenu}
                target={"popover"}
                trigger="focus"
            >
                <PopoverBody className='usermenu-pop' onMouseLeave={() => setShowUserMenu(!showUserMenu)}>
                    {user && user.userType.isWorkshop ? renderWorkshopItems() : null}
                    <div className='usermenu-nav' onClick={(e) => logUserOut() }>
                        <p>Log out</p>
                    </div>
                </PopoverBody>
            </Popover>
        )
    }

    return (
        <div className='top-bar'>
            <div className='nav-logo-wrapper'>
                <img alt='logo' src={Logo} />
            </div>

            <div className='user-info-wrapper'>
                {
                    user && user.userType.isAdministrator ?
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 3 }}>
                            <Tooltip title="Quote Requests">
                                <IconButton onClick={()=> history.push('/qrequest')}>
                                    <Badge showZero={false} badgeContent={_.size(quoteRequests)} color='error'>
                                        <Notifications />
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                        </Box>
                        : null
                }
                <div className='text-wrapper'>
                    <p className='bold'>{user ? user.fullName : null}</p>
                    <div className='account-hover' onClick={(e) => setShowUserMenu(!showUserMenu)}>
                        <p className='position-text' id={'popover'}>{user && user.userType.isAdministrator ? 'Administrator' : 'Workshop'}</p>
                        <FaChevronDown className='chev-icon' />
                        {userMenu()}
                    </div>

                </div>
                <div>
                    <img alt='decrotive' src={UserIcon} />
                </div>
            </div>
        </div>
    )

}