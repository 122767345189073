import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Label, Row, Col, Input, FormText, Alert } from 'reactstrap';
import _ from 'lodash';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { MainButton } from '../../modules/button';
import { CarDetails } from './jobComponents/carDetails';
import { MdDelete, MdRemoveCircleOutline } from 'react-icons/md';
import moment from 'moment';
import customId from 'custom-id';
import { useHistory } from 'react-router-dom';
import Toggle from 'react-toggle';
import { AddressLookup } from '../../modules/addressLookup/index';


export const NewJob = () => {
  const history = useHistory();
  const gstPercentage = 1.15;
  const firestore = useFirestore();
  const { customerList, settingsList: { settings }, user, userList } = useSelector(state => state.firestore.data);
  const [technicans, setTechnicans] = useState([])
  const [loading, setLoading] = useState(false);
  const [customerSearch, setCustomerSearch] = useState('');
  const [customerResults, setCustomerResults] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [newJob, setNewJob] = useState({
    branch: '-', jobType: '', insurance: false, policyNumber: '', insuranceCompany: '', dateOfInstall: '', isOnsite: false, technicans: [], techniciansName: [],
    jobInformation: [], customerDetails: {}, carDetails: {}, status: 'in-progress'
  });
  const [jobInfo, setJobInfo] = useState({ description: '', jobAmount: 0.00, });
  const [totalValues, setTotalValues] = useState({ gst: 0.00, total: 0.00 })
  const [createError, setCreateError] = useState(null);
  const [showNewAddress, setShowNewAddress] = useState(false);
  const [lockedAddress, setLockedAddress] = useState(false);
  const [addressSearchResults, setAddressSearchResults] = useState([]);
  const [searchAddress, setSearchAddress] = useState('');

  useEffect(() => {
    setTechnicans([..._.filter(userList, (u) => {
      if (_.includes(u.branches, newJob.branch) && u.userType.isWorkshop) return u
    })])
  }, [newJob.branch])

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        submitJobDescription();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  });

  useEffect(() => {
    const custList = showCustomers();
    if (!_.isEmpty(customerSearch)) {
      custList.push({ fullName: '+ ADD NEW' })
    }
    setCustomerResults(custList);
    if (_.isEmpty(customerSearch)) {
      setSelectedCustomer(null)
      setNewJob({ ...newJob, customerDetails: {} })
    }
  }, [customerSearch])

  useEffect(() => {
    const { jobInformation } = newJob;
    let gstValue = 0.00;
    let total = 0.00;
    _.each(jobInformation, (job) => {
      const priceGst = job.jobAmount * gstPercentage;
      const gstOnly = priceGst - job.jobAmount;
      gstValue = gstValue + gstOnly;
      total = total + parseFloat(job.jobAmount);

    })
    total = total + gstValue;

    setTotalValues({ gst: gstValue, total: total })
  }, [newJob.jobInformation])

  useEffect(() => {
    const fetchResults = async () => {
      if (!lockedAddress) {
        const results = await AddressLookup(searchAddress);
        setAddressSearchResults(results);
      }
    }
    fetchResults()
  }, [searchAddress]);

  const renderJobItems = () => {
    return _.map(newJob.jobInformation, (item, index) => {
      return (
        <Row key={index} className='job-item-row'>
          <Col lg={9} md={9} sm={8}>
            <p>{item.description}</p>
          </Col>
          <Col>
            <p>${item.jobAmount ? item.jobAmount.toFixed(2) : '0.00'}</p>
          </Col>
          <Col className='delete-col'>
            <MdDelete className='delete-icon' size={24} onClick={(e) => deleteJobDescription(item.key)} />
          </Col>
        </Row>
      )
    })
  }
  const renderTechOptions = () => {
    if (newJob.branch === '-' && (!technicans || _.isEmpty(technicans))) {
      return <option value='-'>Please select a branch first</option>
    }
    else if (newJob.branch !== '-' && _.isEmpty(technicans)) {
      return <option value='-'>No techs assigned to this branch</option>
    }
    else {
      return _.map(technicans, (tech, index) => {
        return <option key={index} value={tech.id}>{tech.fullName}</option>
      })
    }
  }

  const renderSelectedTechs = () => {
    return _.map(newJob.techniciansName, (tech, index) => {
      return <p key={index} className='selected-tech' onClick={(e) => removeTech(tech.id, tech.name)}>{tech.name}</p>
    })
  }

  const addTechnician = async (id) => {
    if (id === '-') return;
    const techn = await firestore.collection('users').doc(id).get();
    const techName = techn.data().fullName;
    if (!_.includes(newJob.technicans, id) && id !== '-') {
      setNewJob({ ...newJob, technicans: [...newJob.technicans, id], techniciansName: [...newJob.techniciansName, { name: techName, id: id }] })
    }
  }

  const removeTech = (id) => {
    setNewJob({
      ...newJob, technicans: [..._.filter(newJob.technicans, (tech) => { return tech !== id })], techniciansName:
        [..._.filter(newJob.techniciansName, (tech) => { return tech.id !== id })]
    })
  }

  const showCustomers = () => {
    if (!customerSearch) {
      return []
    }
    if (!_.isEmpty(customerSearch)) {
      const inputValue = customerSearch.trim().toLowerCase();
      const inputLength = inputValue.length;
      const reg = new RegExp(inputValue + '.+$', 'i');

      const filteredData = inputLength === 0 ? _.map(customerList) : _.filter(customerList, (customer) => {
        return customer.fullName.toLowerCase().search(reg) !== -1
      })
      return filteredData;
    } else {
      return []
    }

  }

  const selectCustomer = (cust) => {
    setCustomerSearch(cust.fullName);
    setSelectedCustomer(cust);
    if (cust.insuranceDetails) {
      setNewJob({
        ...newJob,
        policyNumber: cust.insuranceDetails.policyNumber,
        insuranceCompany: cust.insuranceDetails.insuranceCompany,
        customerDetails: {
          customerName: cust.fullName,
          customerAddress: cust.address.physical,
          customerLocation: cust.location,
          customerEmail: cust.email,
          customerPhone: cust.mobileContact ? cust.mobileContact : cust.homeContact,
          customerId: cust.id,
          customerGeo: {
            long: cust.address.geo.long,
            lat: cust.address.geo.lat,
          },
        }
      })
    } else {
      setNewJob({
        ...newJob, customerDetails: {
          customerName: cust.fullName,
          customerAddress: cust.address.physical,
          customerLocation: cust.location,
          customerPhone: cust.mobileContact ? cust.mobileContact : cust.homeContact,
          customerId: cust.id,
          customerEmail: cust.email,
          customerGeo: {
            long: cust.address.geo.long,
            lat: cust.address.geo.lat,
          },
        }
      })
    }
  }

  const renderCustomerList = () => {
    if (!_.isEmpty(customerResults) && !selectedCustomer) {
      return (
        <div className='customer-list-wrapper'>
          <h6>Search results {_.size(customerResults) - 1}</h6>
          {_.map(customerResults, (cust, index) => {
            if (cust.fullName !== '+ ADD NEW') {
              return (
                <div key={index} onClick={(e) => selectCustomer(cust)} className='cust-result'><p>{cust.fullName}</p>
                  {cust.isCommercial ? <FormText><strong>Commercial</strong></FormText> : null}
                  <FormText>{cust.mobileContact ? cust.mobileContact : cust.homeContact}</FormText>
                  <FormText>{cust.address.physical}</FormText>
                </div>
              )
            } else {
              return <div key={index} className='cust-result' onClick={(e) => window.open('/customers?new=true', '_blank')}><p>{cust.fullName}</p></div>
            }
          })}
        </div>
      )
    } else {
      return null
    }
  }

  const removeCustomer = () => {
    setCustomerSearch('')
    setCustomerResults([])
    setSelectedCustomer(null);
  }

  const renderAutoInfo = () => {
    const { jobType } = newJob;
    if (jobType === 'Auto Glass') {
      return <CarDetails updateFunc={setNewJob} newJob={newJob} />
    }
    if (jobType === 'Tinting - Auto') {
      return <CarDetails updateFunc={setNewJob} newJob={newJob} />
    }
  }

  const submitJobDescription = () => {
    const { description } = jobInfo;
    const index = _.size(newJob.jobInformation);
    if (!_.isEmpty(description)) {
      setNewJob({ ...newJob, jobInformation: [...newJob.jobInformation, { ...jobInfo, key: index }] });
      setJobInfo({ description: '', jobAmount: 0.00 });
    }
  }

  const deleteJobDescription = (key) => {
    setNewJob({ ...newJob, jobInformation: [..._.filter(newJob.jobInformation, (desc) => { return desc.key !== key })] });
  }

  const validation = () => {
    const { branch, jobType, dateOfInstall, technicans,
      jobInformation, customerDetails, carDetails } = newJob;
    if (_.isEmpty(branch) || branch === '-' || _.isEmpty(jobType) || jobType === '-') {
      setLoading(false);
      setCreateError('Please select the branch and jobtype')
      return false;
    }
    /*if (_.isEmpty(dateOfInstall)) {
      setLoading(false);
      setCreateError('Please include the date of install')
      return false;
    }*/
    if (_.isEmpty(technicans)) {
      setLoading(false);
      setCreateError('Please include at least one technican')
      return false;
    }
    if (_.isEmpty(customerDetails)) {
      setLoading(false);
      setCreateError('Please select a customer')
      return false;
    }
    if (!_.isEmpty(customerDetails) && _.isEmpty(customerDetails.customerAddress)) {
      setLoading(false);
      setCreateError('Please enter the customers address')
      return false;
    }
    if (jobType === 'Tinting - Auto' || jobType === 'Auto Glass') {
      if (_.isEmpty(carDetails)) {
        setLoading(false);
        setCreateError('Please include the vehicle details')
        return false;
      }
    }
    if (_.isEmpty(jobInformation)) {
      setLoading(false);
      setCreateError('Please include at least one job task')
      return false;
    }

    return true;

  }

  const submitJob = async () => {
    setLoading(true);
    setCreateError(null);
    const isValid = validation();
    const dateInstall = moment(newJob.dateOfInstall).unix();
    const date = moment().unix();
    if (isValid) {
      let jobSave = { ...newJob };
      if (!jobSave.insurance) {
        delete jobSave.policyNumber;
        delete jobSave.insuranceCompany;
      }
      const newDoc = await firestore.collection('jobs').doc().get();
      await newDoc.ref.set({
        jobId: null,
        id: newDoc.id,
        ...jobSave,
        dateOfInstall: dateInstall,
        audit: {
          createdAt: date,
          createdByName: user.fullName,
          createdById: user.id,
        }
      });
      setLoading(false);
      history.push(`/jobs/view/${newDoc.id}`)
    }
  }

  const setAddress = (match) => {
    const { place_name, geometry: { coordinates } } = match;
    setNewJob({
      ...newJob, customerDetails: {
        ...newJob.customerDetails,
        customerAddress: place_name,
        customerGeo: {
          long: coordinates[0],
          lat: coordinates[1],
        }
      }
    });
    setLockedAddress(true);
    setSearchAddress(place_name);
  }

  const autoCompleteAddress = () => {
    if (_.isEmpty(addressSearchResults)) {
      return null
    } else {
      return (
        <div className='address-auto-complete'>
          {_.map(addressSearchResults.features, (match, index) => {
            return <p className='cursor' key={index} onClick={() => setAddress(match)}>{match.place_name}</p>
          })}
        </div>
      )
    }
  }

  const newAddressToggle = () => {
    if (showNewAddress) {
      // if its true we need to reset the orginal customer address.
      //reset address lock
      setNewJob({
        ...newJob, customerDetails: {
          ...newJob.customerDetails,
          customerAddress: selectedCustomer.address.physical,
          customerGeo: {
            long: selectedCustomer.address.geo.long,
            lat: selectedCustomer.address.geo.lat,
          }
        }
      });
      setSearchAddress('');
      setLockedAddress(false);
      setShowNewAddress(!showNewAddress);
    } else {
      // clear the address so validation doesnt complete without an address
      setNewJob({
        ...newJob, customerDetails: {
          ...newJob.customerDetails,
          customerAddress: '',
          customerGeo: {
            long: selectedCustomer.address.geo.long,
            lat: selectedCustomer.address.geo.lat,
          }
        }
      });
      setShowNewAddress(!showNewAddress);
    }
  }

  const setJobLocation = (type) => {
    if (type === 'Supply only') {
      setNewJob({ ...newJob, isOnsite: false, supplyOnly: true })
    } else {
      setNewJob({ ...newJob, isOnsite: type === 'true' ? true : false, supplyOnly: false })
    }
  }



  return (
    <Container fluid={true}>
      <Container className='new-job-wrapper'>
        <div className='job-update-header'>
          <p className='main-title'>Create new job</p>
        </div>
        <Row>
          <Col>
            <Label>Branch</Label>
            <Input type='select' className='job-input' onChange={(e) => setNewJob({ ...newJob, branch: e.target.value })}>
              <option value='-'>Select</option>
              {_.map(settings ? settings.locations : [], (branch, index) => {
                return <option key={index} value={branch}>{branch}</option>
              })}
            </Input>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Job Type</Label>
            <Input type='select' className='job-input' onChange={(e) => setNewJob({ ...newJob, jobType: e.target.value })}>
              <option value='-'>Select</option>
              <option value='Auto Glass'>Auto Glass</option>
              <option value='Tinting - Auto'>Tinting - Auto</option>
              <option value='Tinting - Home'>Tinting - Home</option>
              <option value='Flatglass'>Flatglass</option>
            </Input>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Date of installation</Label>
            <Input type='date' className='job-input' onChange={(e) => setNewJob({ ...newJob, dateOfInstall: e.target.value })} />
          </Col>
          <Col>
            <Label>Technician(s)</Label>
            <Input type='select' className='job-input' onChange={(e) => addTechnician(e.target.value)}>
              <option value='-'>Select</option>
              {renderTechOptions()}
            </Input>
            {!_.isEmpty(newJob.technicans) ? <div>{renderSelectedTechs()}</div> : null}
            {!_.isEmpty(newJob.technicans) ? <FormText className='text-center'>Click tech name to remove</FormText> : null}

          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Workshop / Onsite / Supply</Label>
            <Input type='select' className='job-input' onChange={(e) => setJobLocation(e.target.value)} >
              <option value={false}>Workshop</option>
              <option value={true}>Onsite</option>
              <option value={'Supply only'}>Supply only</option>
            </Input>
          </Col>
        </Row>
        <p className='subtitle'>Customer Details</p>
        <Row>
          <Col>
            <Label>Name</Label>
            <Input autoComplete='none' className='job-input' onChange={(e) => setCustomerSearch(e.target.value)} value={customerSearch} />
            {selectedCustomer ? <div><MdRemoveCircleOutline className='remove-cust-icon' onClick={() => removeCustomer()} /></div> : null}
            {renderCustomerList()}
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Address</Label>
            {!showNewAddress ? <Input className='job-input' disabled={true} value={selectedCustomer ? selectedCustomer.address.physical : ''} /> :
              <Input className='job-input' disabled={false} value={searchAddress} onChange={(e) => setSearchAddress(e.target.value)} />}
            {!lockedAddress ? autoCompleteAddress() : null}
          </Col>
        </Row>
        <Row>
          <Col>
            {!_.isEmpty(newJob.customerDetails) ? <div className='toggle-div'>
              <Label className='toggle-label'>Change job address</Label>
              <Toggle
                defaultChecked={showNewAddress}
                onChange={() => newAddressToggle()}
              />
            </div> : null}
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Branch</Label>
            <Input className='job-input' disabled={true} value={selectedCustomer ? selectedCustomer.location : ''} />
          </Col>
          <Col>
            <Label>Phone</Label>
            <Input className='job-input' disabled={true}
              value={selectedCustomer ? !_.isEmpty(selectedCustomer.mobileContact) ? selectedCustomer.mobileContact : selectedCustomer.homeContact : ''} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Email address</Label>
            <Input className='job-input' disabled={true} value={selectedCustomer ? selectedCustomer.email : ''} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Insurance</Label>
            <Input type='select' className='job-input' onChange={(e) => setNewJob({ ...newJob, insurance: e.target.value === 'true' ? true : false })} >
              <option value={false}>No</option>
              <option value={true}>Yes</option>
            </Input>
          </Col>
          {newJob.insurance ?
            <Col>
              <Label>Claim / Policy #</Label>
              <Input type='text' className='job-input' value={newJob.policyNumber} onChange={(e) => setNewJob({ ...newJob, policyNumber: e.target.value })} />
              <Label>Insurance company</Label>
              <Input type='text' className='job-input' value={newJob.insuranceCompany} onChange={(e) => setNewJob({ ...newJob, insuranceCompany: e.target.value })} />
            </Col> : null}
        </Row>
        {renderAutoInfo()}
        <p className='subtitle'>Job Information</p>
        <Row className='mb-3 border-bottom'>
          <Col lg={9} md={9} sm={8}>
            <Label>Job Description</Label>
          </Col>
          <Col>
            <Label>Amount (exc GST)</Label>
          </Col>
        </Row>
        {renderJobItems()}
        <Row className='mt-3'>
          <Col lg={9} md={9} sm={8}>
            <Input className='job-input' placeholder='Add job description' value={jobInfo.description} onChange={(e) => setJobInfo({ ...jobInfo, description: e.target.value })} />
          </Col>
          <Col>
            <Input type='number' className='job-input' placeholder='0.00' value={jobInfo.jobAmount} onChange={(e) => setJobInfo({ ...jobInfo, jobAmount: parseFloat(e.target.value) })} />
          </Col>
        </Row>
        <div className='add-button-wrapper'>
          <MainButton text={'+ Add'} className={'add-button'} clickAction={submitJobDescription} />
        </div>
        <div className='flex-end-wrapper border-top'>
          <p>GST   ${totalValues.gst.toFixed(2)}</p>
        </div>
        <div className='flex-end-wrapper bottom border-bottom'>
          <p>Total   ${totalValues.total.toFixed(2)}</p>
        </div>
        <div>
          <MainButton text={'Submit Job'} className={'submit-button'} loading={loading} disabled={loading} clickAction={submitJob} />
        </div>
        {createError ? <Alert className='mt-2' color='danger'>{createError}</Alert> : null}
      </Container>
    </Container>
  );
}
