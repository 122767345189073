import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import {
  Container, Table, Row, Col, Button, Input,
  Modal, ModalBody, ModalHeader, Alert, Label, FormText, ModalFooter
} from 'reactstrap';
import { MainButton } from '../modules/button/index';
import { TableEnd } from '../modules/table/tableEnd';
import { MdRemoveCircleOutline } from 'react-icons/md';
import _ from 'lodash';
import './style.css';
import { UserMenu } from '../modules/table/menuOptions/index';
import Axios from 'axios';
import { NoResults } from '../modules/table/noResults';
import { LoadingTable } from '../modules/table/loading';
import AddIcon from '../../images/custom/add_icon.png';
import SearchIcon from '../../images/custom/search_icon.png';


export const Users = () => {
  const firestore = useFirestore();
  const { user, userList, settingsList } = useSelector(state => state.firestore.data);
  const { requesting } = useSelector(state => state.firestore.status);
  const [completeList, setCompleteList] = useState([]);
  const [displayLimit, setDisplayLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const indexOfLastData = currentPage * displayLimit;
  const indexOfFirstData = indexOfLastData - displayLimit;

  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [createError, setCreateError] = useState(null);
  const [createSuccess, setCreateSuccess] = useState(null);

  const [newUser, setNewUser] = useState({ fullName: '', userType: null, email: '', branches: [], password: '', creatorName: null });
  const [editUser, setEditUser] = useState(null);
  const [isEditOpen, setEditOpen] = useState(null);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false)
  const [updatePassword, setUpdatePassword] = useState('');

  const [showDeleteUserModal, setShowDeleteuserModal] = useState(false);

  useEffect(() => {
    setCompleteList(_.map(userList))
  }, [userList])



  const deleteUserAction = async () => {
    setLoading(true);
    await Axios.post('https://us-central1-onpointdashboard.cloudfunctions.net/deleteUser', { deleteId: editUser.id, requesterId: user.id });
    setLoading(false);
    toggleDeleteUserModal();
  }

  const toggleDeleteUserModal = () => {
    setShowDeleteuserModal(!showDeleteUserModal);
  }

  const tableHeader = () => {
    return (
      <thead className='table-header'>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Account Type</th>
          <th>Branch(s)</th>
          <th></th>
        </tr>
      </thead>
    )
  }

  const renderBranches = (branches) => {
    let count = 0
    let text = '';
    _.each(branches, branch => {
      if (count === _.size(branches) - 1) {
        text = text + `${branch}`
      } else {
        text = text + `${branch}, `

      }
      count++;
    })
    return (
      text
    )
  }

  const showData = () => {
    if (!completeList) {
      return []
    }
    if (!_.isEmpty(searchTerm)) {
      const inputValue = searchTerm.trim().toLowerCase();
      const inputLength = inputValue.length;

      const filteredData = inputLength === 0 ? _.map(completeList) : _.filter(completeList, (user) => {
        return user.fullName.toLowerCase().slice(0, inputLength) === inputValue;
      })
      return filteredData;
    } else {
      return completeList;
    }

  }

  const debounceSearch = (text) => {
    setSearchTerm(text)
  }

  const addBranch = (value) => {
    if (!_.includes(newUser.branches, value) && value !== '-') {
      setNewUser({ ...newUser, branches: [...newUser.branches, value] })
    }
  }

  const addBranchEdit = (value) => {
    if (!_.includes(editUser.branches, value) && value !== '-') {
      setEditUser({ ...editUser, branches: [...editUser.branches, value] })
    }
  }

  const removeBranch = (value) => {
    setNewUser({ ...newUser, branches: [..._.filter(newUser.branches, (branch) => { return branch !== value })] })
  }

  const removeBranchEdit = (value) => {
    setEditUser({ ...editUser, branches: [..._.filter(editUser.branches, (branch) => { return branch !== value })] })
  }

  const addAccountType = (value) => {
    if (value !== '-') {
      setNewUser({ ...newUser, userType: value })
    }
  }

  const addAccountTypeEdit = (value) => {
    if (value !== '-') {
      setEditUser({ ...editUser, userType: value })
    }
  }

  const addUser = () => {
    return (
      <Modal size='lg' isOpen={showAddUserModal} toggle={(e) => setShowAddUserModal(!showAddUserModal)} >
        <ModalHeader toggle={(e) => setShowAddUserModal(!showAddUserModal)}>
          Create new user
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Label>Full name</Label>
              <Input className='default-input' type='text' onChange={(e) => setNewUser({ ...newUser, fullName: e.target.value })} />
            </Col>
            <Col>
              <Label>Email</Label>
              <Input className='default-input' type='email' onChange={(e) => setNewUser({ ...newUser, email: e.target.value })} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Label>Account type</Label>
              <Input className='default-input' type='select' onChange={(e) => addAccountType(e.target.value)}>
                <option value='-'>Select</option>
                <option value='Administrator'>Administrator</option>
                <option value='Workshop'>Workshop</option>
                <option value='Admin-workshop'>Workshop & Admin</option>
              </Input>
            </Col>
            <Col>
              <Label>Password</Label>
              <Input className='default-input' type='text' onChange={(e) => setNewUser({ ...newUser, password: e.target.value })} />
              <FormText>Greater than 6 characters</FormText>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label>Branch(s)</Label>
              <Input type='select' className='default-input' onChange={(e) => addBranch(e.target.value)}>
                <option value='-'>Select</option>
                {_.map(settingsList ? settingsList.settings.locations : [], (branch, index) => {
                  return <option key={index} value={branch}>{branch}</option>
                })}
              </Input>
            </Col>
            <Col className='pt-3'>
              {_.map(newUser.branches, (branch, index) => {
                return <div key={index}>{branch}<MdRemoveCircleOutline onClick={(e) => removeBranch(branch)} className='remove-icon' /></div>
              })}
            </Col>
          </Row>

          {createError ? <Alert color='danger' className='mt-2 mb-4'>{createError}</Alert> : null}
          {createSuccess ? <Alert className='mt-2 mb-4'>{createSuccess}</Alert> : null}
        </ModalBody>
        <div className='center-wrapper'>
          <MainButton className='reset-submit-btn' text={'Create'} loading={loading} disabled={loading} clickAction={createUser} />
        </div>
      </Modal>
    )
  }

  const userIs = () => {
    const { isAdministrator, isWorkshop } = editUser.userType;

    if (isAdministrator && isWorkshop) {
      return 'Admin-workshop'
    } else if (isAdministrator) {
      return 'Administrator'
    } else if (isWorkshop) {
      return 'Workshop'
    }
  }

  const renderOption = () => {
    return _.map(settingsList.settings.locations, (s) => {
      return  <option value={s}>{s}</option>
    })
  }

  const updateUser = () => {
    return (
      <Modal size='lg' isOpen={isEditOpen} toggle={(e) => setEditOpen(!isEditOpen)}>
        <ModalHeader toggle={(e) => setEditOpen(!isEditOpen)}>
          Edit {editUser ? editUser.fullName : null}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Label>Account type</Label>
              <Input value={userIs()} className='default-input' type='select' onChange={(e) => addAccountTypeEdit(e.target.value)}>
                <option value='-'>Select</option>
                <option value='Administrator'>Administrator</option>
                <option value='Workshop'>Workshop</option>
                <option value='Admin-workshop'>Workshop & Admin</option>
              </Input>
            </Col>
            <Col>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label>Branch(s)</Label>
              <Input type='select' className='default-input' onChange={(e) => addBranchEdit(e.target.value)}>
                <option value='-'>Select</option>
                {settingsList ? renderOption() : null}
              </Input>
            </Col>
            <Col className='pt-3'>
              {_.map(editUser.branches, (branch, index) => {
                return <div key={index}>{branch}<MdRemoveCircleOutline onClick={(e) => removeBranchEdit(branch)} className='remove-icon' /></div>
              })}
            </Col>
          </Row>
          {createError ? <Alert color='danger' className='mt-2 mb-4'>{createError}</Alert> : null}
          {createSuccess ? <Alert className='mt-2 mb-4'>{createSuccess}</Alert> : null}
        </ModalBody>
        <div className='center-wrapper'>
          <MainButton className='reset-submit-btn' text={'Update'} loading={loading} disabled={loading} clickAction={updateUserFunc} />
        </div>
      </Modal>
    )
  }

  const createUser = async () => {
    const { fullName, userType, email, branches, password } = newUser;
    setCreateError(null);
    setCreateSuccess(null);
    setLoading(true)
    // empty check
    if (_.isEmpty(fullName) || _.isEmpty(userType) || _.isEmpty(email) || _.isEmpty(branches) || _.isEmpty(password)) {
      setCreateError('Please ensure each field is filled in.')
      setLoading(false)
      return
    }
    if (password.length < 6) {
      setCreateError('Please ensure the password is 6 or greater characters in length')
      setLoading(false)
      return
    }
    try {
      await Axios.post('https://us-central1-onpointdashboard.cloudfunctions.net/createUser', { ...newUser, creatorName: user.fullName })
      setCreateSuccess('User created successfully')
      setLoading(false)
    } catch (ex) {
      console.error(ex);
      setCreateError('Failed to create user, please try again later.')
      setLoading(false)
    }

  }

  const setUserType = (type) => {
    let userType = {

    }

    if (type === 'Administrator') {
      userType = {
        isRootUser: false,
        isAdministrator: true,
        isWorkshop: false,
      }
    } else if (type === 'Workshop') {
      userType = {
        isRootUser: false,
        isAdministrator: false,
        isWorkshop: true,
      }
    } else if (type === 'Admin-workshop') {
      userType = {
        isRootUser: false,
        isAdministrator: true,
        isWorkshop: true,
      }
    } else {
      userType = {
        isRootUser: false,
        isAdministrator: false,
        isWorkshop: false,
      }
    }

    return userType;
  }

  const updateUserFunc = async () => {
    setLoading(true)
    setCreateError(null);
    try {

      const doc = await firestore.collection('users').doc(editUser.id).get();
      if(typeof editUser.userType === 'string') {
        const permissions = setUserType(editUser.userType);
        doc.ref.update({
          ...editUser,
          userType: {
            ...permissions,
          },
        })
      } else {
        doc.ref.update({
          ...editUser,
        })
      }


      setLoading(false)
      setCreateSuccess('Success updated user.')
    } catch (ex) {
      setLoading(false)
      setCreateError('Failed to update user please try again later.')
    }

  }

  const changePasswordFunc = async () => {
    setLoading(true);
    setCreateError(null);
    setCreateSuccess(null);
    if (updatePassword.length > 6) {
      try {
        await Axios.post('https://us-central1-onpointdashboard.cloudfunctions.net/updateUserPassword', { password: updatePassword, userId: editUser.id })
        setCreateSuccess('Password updated.')
        setLoading(false);
      } catch (ex) {
        setLoading(false);
        setCreateError('Unable to update password please try again later')
      }

    } else {
      setCreateError('New password must be greater than 6 characters.')
      setLoading(false);
    }
  }

  const changePassword = () => {
    return (
      <Modal size='md' isOpen={changePasswordOpen} toggle={(e) => setChangePasswordOpen(!changePasswordOpen)}>
        <ModalHeader toggle={(e) => setChangePasswordOpen(!changePasswordOpen)}>
          Edit {editUser ? editUser.fullName : null}
        </ModalHeader>
        <ModalBody>
          <Label>Update password</Label>
          <Input type='text' onChange={(e) => setUpdatePassword(e.target.value)} />
          {createError ? <Alert color='danger' className='mt-2 mb-4'>{createError}</Alert> : null}
          {createSuccess ? <Alert className='mt-2 mb-4'>{createSuccess}</Alert> : null}
        </ModalBody>
        <div className='center-wrapper'>
          <MainButton className='reset-submit-btn' text={'Update'} loading={loading} disabled={loading} clickAction={changePasswordFunc} />
        </div>
      </Modal>
    )
  }

  const renderTableBody = () => {
    const data = showData()
    if (!_.isEmpty(data)) {
      return (
        <tbody>
          {_.map(data.slice(indexOfFirstData, indexOfLastData), (user, index) => {
            if (!user) {
              return null
            }
            return (
              <tr key={index}>
                <td>{user.fullName}</td>
                <td>{user.email}</td>
                <td>{user.userType.isAdministrator ? 'Administrator' : 'Workshop'}</td>
                <td>{renderBranches(user.branches)}</td>
                <UserMenu user={user} setEditOpen={setEditOpen} deleteUserFunc={toggleDeleteUserModal} setEditUser={setEditUser} setChangePasswordOpen={setChangePasswordOpen} menuType={'users'} />
              </tr>
            )
          })}
        </tbody>
      )
    } else {
      return null
    }
  }

  const deleteUserModal = () => {
    return (
      <Modal size='lg' isOpen={showDeleteUserModal} toggle={() => toggleDeleteUserModal()}>
        <ModalHeader toggle={() => toggleDeleteUserModal()}>
          Warning!
        </ModalHeader>
        <ModalBody>
          <p>You are about to delete user <strong>{editUser.fullName}</strong></p>
        </ModalBody>
        <ModalFooter>
          <Button color='danger' disabled={loading} onClick={() => deleteUserAction()}>Yes, Delete</Button>
          <Button disabled={loading} onClick={() => toggleDeleteUserModal()} >Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Container fluid={true}>
      <Row className='search-row'>
        <Col>
          <div>
            <img alt='decorative' className='search-icon' src={SearchIcon} />
            <Input onChange={(e) => debounceSearch(e.target.value)} className='search-input' placeholder='Type a name to search...' />
          </div>
        </Col>
        <Col className='add-user-col' xlg={2} lg={2} md={4} sm={4} xsm={4}>
          <Button className='add-user-btn' onClick={(e) => setShowAddUserModal(!showAddUserModal)}><img alt='decorative' className='plus-icon' src={AddIcon} />ADD USER</Button>
        </Col>
      </Row>
      <Table>
        {tableHeader()}
        {renderTableBody()}
      </Table>
      {requesting.userList ? <LoadingTable /> : null}
      {_.isEmpty(showData()) && !requesting.userList ? <NoResults /> : null}
      <TableEnd completeList={completeList} setDisplayLimit={setDisplayLimit} setCurrentPage={setCurrentPage}
        indexOfLastData={indexOfLastData} indexOfFirstData={indexOfFirstData} displayLimit={displayLimit} currentPage={currentPage} />
      {addUser()}
      {editUser ? updateUser() : null}
      {editUser ? changePassword() : null}
      {editUser ? deleteUserModal() : null}
    </Container>
  );
}
