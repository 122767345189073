import React, { useEffect, useState } from 'react';
import { FiChevronDown, FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { Popover, PopoverBody } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';


export const TableEnd = ({ completeList, filterApplied, filteredDataList, setCurrentPage, indexOfLastData, indexOfFirstData, setDisplayLimit, displayLimit, currentPage }) => {

    const dispatch = useDispatch();
    const [showDisplayLimitMenu, setDisplayLimitMenu] = useState(false)
    const [maxPages, setMaxPages] = useState(1);
    const [listSize, setListSize] = useState(0);

    useEffect(() => {
        if (!filterApplied) {
            setListSize(_.size(completeList));
            setMaxPages(Math.ceil(_.size(completeList) / displayLimit))
        } else {
            setListSize(_.size(filteredDataList));
            setMaxPages(Math.ceil(_.size(filteredDataList) / displayLimit))
        }

    })

    const changeDisplayLimit = (value) => {
        setDisplayLimit(value)
        setDisplayLimitMenu(!showDisplayLimitMenu)
    }

    const boldPastPage1 = () => {
        if(currentPage > 1) {
            return <p><strong>{indexOfFirstData + 1}-{indexOfLastData > listSize ? listSize : indexOfLastData}</strong> of {listSize}</p>
        } else {
            return  <p>{indexOfFirstData + 1}-{indexOfLastData > listSize ? listSize : indexOfLastData} of {listSize}</p>
        }
    }

    const displayLimitMenu = () => {
        return (
            <Popover
                placement={"bottom"}
                isOpen={showDisplayLimitMenu}
                target={"row-popover"}
                trigger="focus"
            >
                <PopoverBody onMouseLeave={(e) => setDisplayLimitMenu(!showDisplayLimitMenu)}>
                    <p className='cursor-pointer' onClick={(e) => changeDisplayLimit(10)}>10</p>
                    <p className='cursor-pointer' onClick={(e) => changeDisplayLimit(15)}>15</p>
                    <p className='cursor-pointer' onClick={(e) => changeDisplayLimit(20)}>20</p>
                </PopoverBody>
            </Popover>
        )
    }

    if (_.isEmpty(completeList)) {
        return null
    }


    return (
        <div className='table-end'>
            <div className='rows-per-page'>
                <p>Rows per page: {displayLimit}</p>
                <FiChevronDown id='row-popover' className='row-icon' size={16} onClick={(e) => setDisplayLimitMenu(!showDisplayLimitMenu)} />
                {displayLimitMenu()}
            </div>
            <div className='next-page'>
                {boldPastPage1()}
            </div>
            <div className='next-page'>
                <FiChevronLeft size={24} className='next-icons' onClick={(e) => {
                    if (currentPage !== 1) {
                        dispatch({
                            type: 'SETPAGE',
                            currentPage: currentPage - 1
                        })
                        setCurrentPage(currentPage - 1)
                    }
                }} />
                <FiChevronRight size={24} className='next-icons' onClick={(e) => {
                    if (currentPage !== maxPages && maxPages !== 0) {
                        dispatch({
                            type: 'SETPAGE',
                            currentPage: currentPage + 1
                        })
                        setCurrentPage(currentPage + 1)
                    }
                }} />
            </div>
        </div>
    )
}

