
export const jobsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SETPAGE':
      let addPageState = {
        ...state,
        currentPage: action.currentPage
      }
      return addPageState;
    case 'CLEARSEARCH':
      let clearSearchString = {
        ...state,
        searchString: ''
      }
      return clearSearchString;
    case 'SET':
      let addState = {
        ...state,
        searchString: action.payload
      }
      return addState;
    case 'SETLOCATION':
      let locationState = {
        ...state,
        filterLocation: action.payload
      }
      return locationState;
    case 'SETDATES':
      let dateState = {
        ...state,
        filterDateStart: action.start,
        filterDateEnd: action.end
      }
      return dateState;
    case 'SETSTATUS':
      let statusState = {
        ...state,
        filterStatus: action.payload
      }
      return statusState;
    case 'SETJOBTYPE':
      let jobTypeState = {
        ...state,
        filterJobType: action.payload
      }
      return jobTypeState;
    case 'REMOVE':
      let removeState = {
        searchString: null,
      }
      return removeState
    case 'CLEARFILTERS':
      let clearState = {
        searchString: null,
        filterStatus: null,
        filterDateStart: null,
        filterDateEnd: null,
        filterLocation: null,
        filterJobType: null,
        currentPage: 1,
      }
      return clearState
    default:
      return state
  }
}