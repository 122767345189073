import React, { useState } from 'react';
import { Container, Button, } from 'reactstrap';
import { useSelector } from 'react-redux';
import { AdminDashboard } from './admin';
import './style.css';
import { WorkshopDashboard } from './workshop';


export const Dashboard = () => {

  const { user } = useSelector(state => state.firestore.data);
  const [loadAdmin, setLoadAdmin] = useState(false);
  const [loadWork, setLoadWork] = useState(false);

  if(!user) {
    return null
  }

  if(user.userType.isAdministrator && user.userType.isWorkshop && !loadAdmin && !loadWork) {
    return(
      <Container>
        <div>
          <Button onClick={()=> setLoadWork(true)}>Load Workshop dash</Button>
        </div>
        <div className='mt-3'>
          <Button onClick={()=> setLoadAdmin(true)}>Load Admin dash</Button>
        </div>
      </Container>
    )
  }

  if(user.userType.isAdministrator && !user.userType.isWorkshop || loadAdmin) {
    return (
      <AdminDashboard />
    );
  }

  if(user.userType.isWorkshop && !user.userType.isAdministrator || loadWork) {
    return (
      <WorkshopDashboard />
    );
  }

  return (
    <WorkshopDashboard />
  );
}
