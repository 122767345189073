import React, { useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DeleteForever } from '@mui/icons-material';
import { ActionWarning } from './warning';
import { useSelector } from 'react-redux';
import _ from 'lodash'
import { useFirestore } from 'react-redux-firebase';
/*
      valueGetter: (params) =>
        `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    }, */




export const QuotesRequest = () => {
    const [warningInfo, setWarningInfo] = useState({ open: false, text: '', data: null })
    const firestore = useFirestore();
    const data = useSelector(state => state.firestore.data)
    const [quotes, setQuotes] = useState([])
    const [loading, setLoading] = useState(false)


    const deleteQuote = async () => {
        try {
            setLoading(true)
            await firestore.collection('quoteRequest').doc(warningInfo.data.id).delete();
            setLoading(false)
            closeWarning()
        } catch(ex) {
            console.log(ex)
            setLoading(false)
        }
    }

    const columns = [
        {
            field: 'first_name',
            headerName: 'Name',
            editable: false,
            flex: 0.1,
            renderCell: (params) => {
                return `${params.row.first_name} ${params.row.last_name}`
            }
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 150,
            flex: 0.1,
        },
        {
            field: 'phone_number',
            headerName: 'Phone',
            sortable: false,
            flex: 0.1,
        },
        {
            field: 'street_address',
            headerName: 'Location',
            flex: 0.1,
            renderCell: (params) => {
                return `${params.row.street_address}, ${params.row.suburb}, ${params.row.city}`
            }
        },
        {
            field: 'joinery_type',
            headerName: 'Joinery Type',
            flex: 0.1,
        },
        {
            field: 'time_frame',
            headerName: 'Time Frame',
            flex: 0.1,
        },
        {
            field: 'additional_information',
            headerName: 'Additional Information',
            flex: 0.2,
        },
        {
            field: 'submittedDate',
            headerName: 'Submitted',
            flex: 0.1,
            renderCell: (params) => {
                const date = Date(params.row.submittedDate.seconds);
                const newDate = new Date(date)
                return `${newDate.toLocaleDateString('en-NZ')}`
            }
        },
        {
            field: 'action',
            headerName: 'Actions',
            width: 160,
            renderCell: (requestParams) => {
                return <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <IconButton onClick={() => openWarning('delete', requestParams)} >
                        <DeleteForever />
                    </IconButton>
                </Box>
            }
        }

    ];

    useEffect(() => {
        const quotes = _.map(data.quoteRequests, (value, index) => {
            if(!value) return
            const addId = {
                ...value,
                id: index,
            }
            return addId
        })
        setQuotes([..._.compact(quotes)])
    }, [data.quoteRequests])




    const openWarning = (type, data) => {
        if (type === 'delete') {
            setWarningInfo({ open: true, text: 'Warning you are about to delete this record', data: data.row, })
        }
    }



    const closeWarning = () => {
        setWarningInfo({ open: false, text: '', data: null })
    }

    return (
        <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={quotes}
                columns={columns}
                pageSize={5}
                isRowSelectable={false}
                rowsPerPageOptions={[5]}
            />
            <ActionWarning open={warningInfo.open} text={warningInfo.text} actionFunction={deleteQuote} closeFunction={closeWarning} loading={loading} />
        </Box>
    )
}

