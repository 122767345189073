import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Table, Row, Col, Button, Input, UncontrolledTooltip, Popover, PopoverBody, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { UserMenu } from '../modules/table/menuOptions/index';
import _ from 'lodash';
import { TableEnd } from '../modules/table/tableEnd';
import { NoResults } from '../modules/table/noResults';
import { useFirestore } from 'react-redux-firebase';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { LoadingTable } from '../modules/table/loading';
import { FaChevronDown } from 'react-icons/fa';
import { MdClear } from 'react-icons/md';
import SearchIcon from '../../images/custom/search_icon.png';
import AddIcon from '../../images/custom/add_icon.png';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import nz from 'date-fns/locale/en-NZ';

import "react-datepicker/dist/react-datepicker.css";
import './style.css';




export const Jobs = () => {
  moment.locale('en-nz')
  registerLocale('nz', nz)
  const dispatch = useDispatch();
  const firestore = useFirestore();
  const params = useParams();
  const history = useHistory();
  const { jobsReducer } = useSelector(state => state)
  const { allJobs } = useSelector(state => state.firestore.data);
  const { requesting } = useSelector(state => state.firestore.status);
  const [completeList, setCompleteList] = useState([]);
  const [filterApplied, setFilterApplied] = useState(false);
  //let completeList = allJobs ? _.map(allJobs) : [];
  const [searchTerm, setSearchTerm] = useState('');
  const [filterJobStatus, setFilterJobStatus] = useState(null);
  const [filterJobType, setFilterJobType] = useState(null);
  const [showJobTypeFilter, setShowJobTypeFilter] = useState(false);
  const [showJobFilter, setShowJobFilter] = useState(false);
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [locationFilter, setLocationFilter] = useState(null);
  const [showLocationFilter, setShowLocationFilter] = useState(false);
  const [showDeleteWarning, setDeleteWarning] = useState(false);
  const [jobInfo, setJobInfo] = useState(null);
  const [techFilter, setTechFilter] = useState(null)
  const [currentPage, setCurrentPage] = useState(1);
  const [displayLimit, setDisplayLimit] = useState(10);
  const indexOfLastData = currentPage * displayLimit;
  const indexOfFirstData = indexOfLastData - displayLimit;

  useEffect(() => {
    if (params.hasOwnProperty('id')) {
      setTechFilter({ id: params.id, name: params.name })
      setFilterApplied(true)
    }
    if(params.hasOwnProperty('location')) {
      setLocationFilter(params.location);
    }
    if(params.hasOwnProperty('status')) {
      setFilterJobStatus(params.status);
    }
  
 
    if (jobsReducer.hasOwnProperty('searchString') && !_.isEmpty(jobsReducer.searchString)) {
      setSearchTerm(jobsReducer.searchString);
    }
    if (jobsReducer.hasOwnProperty('filterStatus') && !_.isEmpty(jobsReducer.filterStatus)) {
      setFilterJobStatus(jobsReducer.filterStatus)
    }
    if (jobsReducer.hasOwnProperty('filterJobType') && !_.isEmpty(jobsReducer.filterJobType)) {
      setFilterJobType(jobsReducer.filterJobType)
    }
    if (jobsReducer.hasOwnProperty('filterLocation') && !_.isEmpty(jobsReducer.filterLocation)) {
      setLocationFilter(jobsReducer.filterLocation)
    }
    if (jobsReducer.hasOwnProperty('filterDateStart') && jobsReducer.filterDateStart) {

      setStartDate(jobsReducer.filterDateStart)
    }
    if (jobsReducer.hasOwnProperty('filterDateEnd') && jobsReducer.filterDateEnd) {
      setEndDate(jobsReducer.filterDateEnd);
    }
    // set page to what it was previous?
    if (jobsReducer.hasOwnProperty('currentPage')) {
      setCurrentPage(jobsReducer.currentPage)
    }

  }, [])

  useEffect(() => {
    //setCompleteList(allJobs)
  }, [allJobs])

  const toggleDeleteWarning = () => {
    setDeleteWarning(!showDeleteWarning);
  }

  const onChange = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setFilterApplied(true);
    dispatch({
      type: 'SETDATES',
      start: start,
      end: end,
    })
  };

  const clearDates = () => {
    setStartDate(null);
    setEndDate(null);
    dispatch({
      type: 'SETDATES',
      start: null,
      end: null,
    })
  }

  const clearAllFilters = () => {
    setStartDate(new Date());
    setEndDate(null);
    setLocationFilter(null);
    setFilterJobType(null);
    setFilterJobStatus(null)
    setFilterApplied(false)
    setTechFilter(null);
    dispatch({
      type: 'CLEARFILTERS',
      payload: null,
    })
    setCurrentPage(1);
  }

  const renderClearAllFilters = () => {
    if (endDate || locationFilter || filterJobStatus || techFilter) {
      return (
        <div>
          <MdClear color='red' size={24} className='cursor-pointer-only' id='clear-tip' onClick={() => clearAllFilters()} />
          <UncontrolledTooltip placement="top" target="clear-tip">
            Click to clear all filters
          </UncontrolledTooltip>
        </div>
      )
    }
  }


  const tableHeader = () => {
    return (
      <thead className='table-header'>
        <tr>
          <th onClick={() => setShowDateFilter(!showDateFilter)} >Date Logged <FaChevronDown className='chev-icon cursor-pointer-only' id='date-toggle' /></th>
          <th>Job ID</th>
          <th>Customer Name</th>
          <th>Contact</th>
          <th onClick={() => setShowJobTypeFilter(!showJobTypeFilter)}>Job Type<FaChevronDown className='chev-icon cursor-pointer-only' id='job-type' /></th>
          <th>Job Location</th>
          <th onClick={() => setShowLocationFilter(!showLocationFilter)}>Location <FaChevronDown className='chev-icon cursor-pointer-only' id='location-filter' /></th>
          <th onClick={() => setShowJobFilter(!showJobFilter)}>Status <FaChevronDown className='chev-icon cursor-pointer-only' id='job-status' /></th>
          <th>{renderClearAllFilters()}</th>
        </tr>
      </thead>
    )
  }

  const setJobTypeFilter = (value) => {
    setFilterJobType(value);
    setShowJobTypeFilter(!showJobTypeFilter)
    setFilterApplied(true)
    dispatch({
      type: 'SETJOBTYPE',
      payload: value,
    })
  }

  const setStatusFilter = (value) => {
    setFilterJobStatus(value);
    setShowJobFilter(!showJobFilter)
    setFilterApplied(true)
    dispatch({
      type: 'SETSTATUS',
      payload: value,
    })
  }

  const locaFilter = (value) => {
    setLocationFilter(value);
    setShowLocationFilter(!showLocationFilter)
    setFilterApplied(true)
    dispatch({
      type: 'SETLOCATION',
      payload: value,
    })
  }

  const headerFilters = () => {
    return (
      <div>
        <Popover
          placement={"bottom"}
          isOpen={showJobFilter}
          target={"job-status"}
          trigger="legacy"
        >
          <PopoverBody>
            <p className='cursor-pointer' onClick={() => setStatusFilter(null)}>All</p>
            <p className='cursor-pointer' onClick={() => setStatusFilter('in-progress')} value='in-progress'>In-progress</p>
            <p className='cursor-pointer' onClick={() => setStatusFilter('quote')} value='quote'>Quoted</p>
            <p className='cursor-pointer' onClick={() => setStatusFilter('to be quoted')} value='to be quoted'>To be Quoted</p>
            <p className='cursor-pointer' onClick={() => setStatusFilter('complete')} value='complete'>Complete</p>
            <p className='cursor-pointer' onClick={() => setStatusFilter('on-hold')} value='on-hold'>On-Hold</p>
            <p className='cursor-pointer' onClick={() => setStatusFilter('awaiting payment')} value='awaiting payment'>Invoiced</p>
            <p className='cursor-pointer' onClick={() => setStatusFilter('cancelled')} value='cancelled'>Cancelled</p>
          </PopoverBody>
        </Popover>
        <Popover
          placement={"bottom"}
          isOpen={showJobTypeFilter}
          target={"job-type"}
          trigger="legacy"
        >
          <PopoverBody>
            <p className='cursor-pointer' onClick={() => setJobTypeFilter(null)}>All</p>
            <p className='cursor-pointer' onClick={() => setJobTypeFilter('Auto Glass')} value='Auto Glass'>Auto Glass</p>
            <p className='cursor-pointer' onClick={() => setJobTypeFilter('Flatglass')} value='Flatglass'>Flatglass</p>
            <p className='cursor-pointer' onClick={() => setJobTypeFilter('Tinting - Auto')} value='Tinting - Auto'>Tinting - Auto</p>
            <p className='cursor-pointer' onClick={() => setJobTypeFilter('Tinting - Home')} value='Tinting - Home'>Tinting - Home</p>
          </PopoverBody>
        </Popover>
        <Popover
          placement={"bottom"}
          isOpen={showDateFilter}
          target={"date-toggle"}
          trigger="legacy"
        >
          <PopoverBody>
            <DatePicker
              selected={startDate}
              onChange={(dates) => onChange(dates)}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
              locale="nz"
            />
            <div className='date-btn-wrapper'>
              <Button onClick={() => setShowDateFilter(!showDateFilter)}>Exit</Button>
              <Button onClick={() => clearDates()}>Clear</Button>
            </div>
          </PopoverBody>
        </Popover>
        <Popover
          placement={"bottom"}
          isOpen={showLocationFilter}
          target={"location-filter"}
          trigger="legacy"
        >
          <PopoverBody>
            <p className='cursor-pointer' onClick={() => locaFilter(null)}>All</p>
            <p className='cursor-pointer' onClick={() => locaFilter('Alexandra')} value='Alexandra'>Alexandra</p>
            <p className='cursor-pointer' onClick={() => locaFilter('Cromwell')} value='Cromwell'>Cromwell</p>
            <p className='cursor-pointer' onClick={() => locaFilter('Wanaka')} value='Wanaka'>Wanaka</p>
          </PopoverBody>
        </Popover>
      </div>
    )
  }

  const jobFilters = (filteredData) => {
    // filter jobs

    let returnData = filteredData;
    if (filterJobStatus) {
      returnData = _.filter(returnData, (job) => {
        // if a job is deleted maintains an entry but with no objects :/
        if (!job) return null
        if (job.hasOwnProperty('status')) {
          return job.status === filterJobStatus
        } else {
          return null
        }
      })
    }
    if (startDate && endDate) {

      returnData = _.filter(returnData, (job) => {
        if (job.audit.createdAt >= moment(startDate).unix() && job.audit.createdAt <= moment(endDate).unix()) {
          return job;
        }
      })
    }

    if (filterJobType) {
      returnData = _.filter(returnData, (job) => {
        return job.jobType === filterJobType
      })
    }

    if (locationFilter) {
      returnData = _.filter(returnData, (job) => {
        return job.branch === locationFilter
      })
    }

    if (techFilter) {
      returnData = _.filter(returnData, (job) => {
        let showJob = false;
        _.map(job.technicans, (tech) => {
          if (tech === techFilter.id) {
            if (filterJobStatus) {
              if (job.status === filterJobStatus) {
                showJob = true
              }
            } else if (job.status === 'in-progress') {
              showJob = true;
            }

          }
        })
        if (showJob) {
          return job
        }
      })
    }

    return returnData

  }

  const showData = () => {
    if (!allJobs) {
      return []
    }
    const sortedList = _.sortBy(allJobs, (list) => {
      if (!list) return null;
      return list.audit.createdAt;
    })
    sortedList.reverse();
    if (!_.isEmpty(searchTerm)) {
      const inputValue = searchTerm.trim().toLowerCase();
      const inputLength = inputValue.length;
      const reg = new RegExp(inputValue + '.+$', 'i');

      let filteredData = inputLength === 0 ? _.map(sortedList) : _.filter(sortedList, (user) => {
        if (!user) return null
        if (_.isEmpty(user.carDetails)) {
          return user.customerDetails.customerName.toLowerCase().search(reg) !== -1 || user.jobId === parseInt(inputValue);
        } else {
          if (user.carDetails.hasOwnProperty('rego')) {
            return user.customerDetails.customerName.toLowerCase().search(reg) !== -1 || user.jobId === parseInt(inputValue) ||
              user.carDetails.rego.toLowerCase().slice(0, inputLength) === inputValue;
          } else {
            return user.customerDetails.customerName.toLowerCase().search(reg) !== -1 || user.jobId === parseInt(inputValue)
          }
        }
      })

      filteredData = jobFilters(filteredData); // filter jobs

      return filteredData;
    } else {
      const returnData = jobFilters(sortedList)
      return returnData;
    }

  }

  const renderJobStatus = (status) => {
    if (status === 'quote') {
      return <td><p className='in-prog purple'>quoted</p></td>
    }
    if (status === 'to be quoted') {
      return <td><p className='in-prog purple'>to be quoted</p></td>
    }
    if (status === 'in-progress') {
      return <td><p className='in-prog'>In-progress</p></td>
    }
    if (status === 'complete') {
      return <td><p className='in-prog red'>Complete</p></td>
    }
    if (status === 'awaiting payment') {
      return <td><p className='in-prog green'>Invoiced</p></td>
    }
    if (status === 'on-hold') {
      return <td><p className='in-prog gray'>On-Hold</p></td>
    }
    if (status === 'cancelled') {
      return <td><p className='in-prog black'>Cancelled</p></td>
    }
  }

  const renderJobLocation = (job) => {
    if (job.isOnsite) {
      return <p className='commercial-p'>Onsite</p>
    } else if (!job.hasOwnProperty('isOnsite')) {
      return <p className='normal'>N.A</p>
    } else if (!job.isOnsite && !job.supplyOnly) {
      return <p className='normal'>Workshop</p>
    } else if (!job.isOnsite && job.supplyOnly) {
      return <p className='normal'>Supply only</p>
    }
  }

  const navOnDoubleClick = (id) => {
    if (!_.isEmpty(searchTerm)) {
      dispatch({
        type: 'SET',
        payload: searchTerm,
      })
    }
    history.push(`/jobs/view/${id}`)
  }

  const renderTableBody = () => {
    let data = showData();
    if (!_.isEmpty(data)) {
      return (
        <tbody>
          {_.map(data.slice(indexOfFirstData, indexOfLastData), (job, index) => {
            if (!job) {
              return null;
            }
            const { customerDetails: { customerName, customerPhone }, status, jobType, branch, audit: { createdAt }, jobId } = job;
            return (
              <tr key={index} onDoubleClick={() => navOnDoubleClick(job.id)} style={{cursor: 'pointer'}}>
                <td>{moment.unix(createdAt).format('DD/MM/YYYY')}</td>
                <td>{jobId}</td>
                <td>{customerName}</td>
                <td>{customerPhone}</td>
                <td>{jobType}</td>
                <td className='flex-td'>{renderJobLocation(job)}</td>
                <td>{branch}</td>
                {renderJobStatus(status)}
                <UserMenu user={job} setEditOpen={null} setEditUser={null} menuType={'jobs'} setJobInfo={setJobInfo} showDeleteJobWarning={toggleDeleteWarning} search={searchTerm} />
              </tr>
            )
          })}
        </tbody>
      )
    } else {
      return null
    }
  }

  const deleteJob = async () => {
    await firestore.collection('jobs').doc(jobInfo.id).delete();
    setJobInfo(null);
    toggleDeleteWarning();
  }


  const deleteWarning = () => {
    if (!jobInfo) return null
    return (
      <Modal size='lg' isOpen={showDeleteWarning} toggle={() => toggleDeleteWarning()}>
        <ModalHeader toggle={() => toggleDeleteWarning()}>
          Warning!
        </ModalHeader>
        <ModalBody>
          <p>You are about to delete job <strong>{jobInfo.jobId}</strong> are you sure?</p>
        </ModalBody>
        <ModalFooter>
          <Button color='danger' onClick={() => deleteJob()}>Yes, Delete</Button>
          <Button onClick={() => toggleDeleteWarning()} >Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Container fluid={true}>
      <Row className='search-row'>
        <Col>
          <div>
            <img alt='decorative' className='search-icon' src={SearchIcon} />
            <Input value={searchTerm} onChange={(e) => {
              setSearchTerm(e.target.value)
              dispatch({
                type: 'SETPAGE',
                currentPage: 1
              })
              setCurrentPage(1)
            }} className='search-input' placeholder='Type a name, job id or car rego to search...' />
          </div>
        </Col>
        <Col xlg={2} lg={2} md={4} sm={4} xsm={4} >
          <Button className='add-user-btn' onClick={(e) => {
            setSearchTerm('');
            dispatch({
              type: 'CLEARSEARCH',
              searchString: '',
            })
            dispatch({
              type: 'SETPAGE',
              currentPage: 1
            })
            setCurrentPage(1)
          }}>Clear Search</Button>
        </Col>
        <Col className='add-user-col' xlg={2} lg={2} md={4} sm={4} xsm={4}>
          <Button className='add-user-btn' onClick={(e) => history.push('/jobs/new')}><img alt='decorative' className='plus-icon' src={AddIcon} />ADD JOB</Button>
        </Col>
      </Row>
      <Table>
        {tableHeader()}
        {renderTableBody()}
      </Table>
      {requesting.allJobs ? <LoadingTable /> : null}
      {_.isEmpty(showData()) && !requesting.allJobs ? <NoResults /> : null}
      <TableEnd completeList={allJobs} filterApplied={filterApplied} filteredDataList={showData()} setDisplayLimit={setDisplayLimit} setCurrentPage={setCurrentPage}
        indexOfLastData={indexOfLastData} indexOfFirstData={indexOfFirstData} displayLimit={displayLimit} currentPage={currentPage} />
      {headerFilters()}
      {deleteWarning()}
    </Container>
  );
}
