import React, { useEffect, useRef, memo, useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { Draggable } from '@fullcalendar/interaction';



export const CustomEvent = memo(({ event, index }) => {
    let elRef = useRef(null);
    const [showPop, setShowPop] = useState(false);

    useEffect(() => {
        let draggable = new Draggable(elRef.current, {
            itemSelector: `.fc-event-${event.jobId}`,
            eventData: function (eventEl) {
                return {
                    title: event.jobId,
                    data: event.id,
                    create: false,
                };
            }
        });
        return () => draggable.destroy();
    });

    return (
        <div className='external-event'>
            <div
                onClick={() => setShowPop(!showPop)}
                ref={elRef}
                className={`fc-event-${event.jobId}`}
                title={event.jobId}
                data={event.id}
                id={index}
                key={index}
            >
                {event.jobId}
            </div>
            <UncontrolledTooltip target={elRef} placement='bottom'>
                {event.customerDetails.customerName}
            </UncontrolledTooltip>
        </div>
    )
});
