import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Table, Row, Col, Button, Input, Label, ModalHeader, Modal, ModalBody, Alert } from 'reactstrap';
import { MainButton } from '../modules/button/index';
import { UserMenu } from '../modules/table/menuOptions/index';
import './style.css';
import _ from 'lodash';
import { TableEnd } from '../modules/table/tableEnd';
import { NoResults } from '../modules/table/noResults';
import { useFirestore } from 'react-redux-firebase';
import { LoadingTable } from '../modules/table/loading';
import { AddressLookup } from '../modules/addressLookup/index';
import { MdRemoveCircleOutline } from 'react-icons/md';
import AddIcon from '../../images/custom/add_icon.png';
import SearchIcon from '../../images/custom/search_icon.png';


export const Customers = () => {
  const firestore = useFirestore();
  const { customerList, settingsList } = useSelector(state => state.firestore.data);
  const { requesting } = useSelector(state => state.firestore.status);
  const [ completeList, setCompleteList ] = useState([]);
  //let completeList = customerList ? _.map(customerList) : [];
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [displayLimit, setDisplayLimit] = useState(10);
  const indexOfLastData = currentPage * displayLimit;
  const indexOfFirstData = indexOfLastData - displayLimit;
  const [isEditOpen, setEditOpen] = useState(null);

  const [newCustomer, setNewCustomer] = useState({fullName: '', homeContact: '', mobileContact: '', email: '',
   location: '', address: { physical: '', geo: { long: '', lat: ''}}})
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [createError, setCreateError] = useState(null);
  const [createSuccess, setCreateSuccess] = useState(null);
  const [addressSearchResults, setAddressSearchResults] = useState([]);
  const [lockedAddress, setLockedAddress] = useState(false);

  useEffect(()=>{
    setCompleteList(_.map(customerList))
  }, [customerList])

  useEffect(() => {
    const url = window.location.search;
    const urlParams = new URLSearchParams(url);
    const open = urlParams.get('new')
    if(open) {
     setShowAddCustomerModal(!showAddCustomerModal)
    }
  }, [])

  useEffect(() => {
    const {address: { physical } } = newCustomer;
    const fetchResults = async () => {
       if(!lockedAddress) {
        const results = await AddressLookup(physical);
        setAddressSearchResults(results);
       }
    }
    fetchResults()
  },[newCustomer.address.physical]);

  const tableHeader = () => {
    return(
      <thead className='table-header'>
      <tr>
        <th>Name</th>
        <th>Home Contact #</th>
        <th>Mobile Contact #</th>
        <th>Email</th>
        <th>Customer Type</th>
        <th>Location</th>
        <th></th>
      </tr>
      </thead>
    )
  }
  
  const showData = () => {
    if(!completeList) {
      return []
    }
    if(!_.isEmpty(searchTerm)) {
      const inputValue = searchTerm.trim().toLowerCase();
      const inputLength = inputValue.length;
      const reg = new RegExp(inputValue+'.+$', 'i');
    
      const filteredData = inputLength === 0 ? _.map(completeList) : _.filter(completeList, (user) => {
        return user.fullName.toLowerCase().search(reg) !== -1
      })
      return filteredData;
    } else {
      return completeList;
    }

  }

  const renderTableBody = () => {
    const data = showData();
    if(!_.isEmpty(data)) {
      return (
      <tbody>
        {_.map(data.slice(indexOfFirstData, indexOfLastData), (customer, index) => {
        if(!customer) {
          return null
        }
        return(
          <tr key={index}>
            <td>{customer.fullName}</td>
            <td>{customer.homeContact}</td>
            <td>{customer.mobileContact}</td>
            <td>{customer.email}</td>
            <td>{customer.isCommercial ? 'Commercial' : null}</td>
            <td>{customer.location}</td>
            <UserMenu user={customer} setEditOpen={setEditOpen} setEditUser={null} menuType={'customers'}/>        
          </tr>
        )
      })}
      </tbody>
      )
    } else {
      return null
    }
  }

  const createCustomer = async () => {
    const { fullName, homeContact, mobileContact, location, address, address: { physical }, email } = newCustomer;
    setLoading(true);
    setCreateError(null);
    setCreateSuccess(null);
    if(_.isEmpty(fullName)) {
      setCreateError('Please ensure a name is provided');
      setLoading(false);
      return
    }
    if(_.isEmpty(homeContact) && _.isEmpty(mobileContact)) {
      setCreateError('Please ensure either a mobile or home number is provided.');
      setLoading(false);
      return
    }
    if(location === '-' || _.isEmpty(location)) {
      setCreateError('Please ensure location is valid');
      setLoading(false);
      return
    }
    if(_.isEmpty(physical)) {
      setCreateError('Please include the customers street address');
      setLoading(false);
      return
    }
    const details = {
      fullName,
      homeContact,
      mobileContact,
      location,
      address,
      email,
    }
    try{
      const customerDoc = await firestore.collection('customers').doc().get();
      const id = customerDoc.ref.id
      customerDoc.ref.set({
        id,
        ...details,
      })
      setLoading(false);
      setCreateSuccess('Customer created successfully.')
    } catch(ex) {
      setCreateError('Something went wrong... please try again.');
      setLoading(false);
    }

  }

  const setAddress = (match) => {
    const { place_name, geometry: { coordinates } } = match;
    setNewCustomer({...newCustomer, address: { physical: place_name, geo: { long: coordinates[0], lat: coordinates[1]}}});
    setLockedAddress(true);
  }

  const autoCompleteAddress = () => {
    if(_.isEmpty(addressSearchResults.features)) {
      return null
    } else {
      return (
        <div className='address-auto-complete'>
          {_.map(addressSearchResults.features, (match, index) => {
            return <p className='cursor' key={index} onClick={() => setAddress(match)}>{match.place_name}</p>
          })}
        </div>
      )
    }
  }

  const addCustomer = () => {
    return(
      <Modal size='lg' isOpen={showAddCustomerModal} toggle={(e)=>setShowAddCustomerModal(!showAddCustomerModal)}>
        <ModalHeader toggle={(e)=>setShowAddCustomerModal(!showAddCustomerModal)}>
          Create new customer
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Label>Full name</Label>
              <Input className='default-input' type='text' onChange={(e) => setNewCustomer({...newCustomer, fullName: e.target.value})}/>
            </Col>
            <Col>
              <Label>Email</Label>
              <Input className='default-input' type='email' onChange={(e) => setNewCustomer({...newCustomer, email: e.target.value})}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label>Home contact #</Label>
              <Input className='default-input' type='text' onChange={(e) => setNewCustomer({...newCustomer, homeContact: e.target.value})}/>
            </Col>
            <Col>
              <Label>Mobile contact #</Label>
              <Input className='default-input' type='email' onChange={(e) => setNewCustomer({...newCustomer, mobileContact: e.target.value})}/>
            </Col>
          </Row>
          <Row>
            <Col>
            <Label>Branch</Label>
              <Input className='default-input' type='select' onChange={(e) => setNewCustomer({...newCustomer, location: e.target.value})}>
                <option value='-'>Select</option>
                {_.map(settingsList ? settingsList.settings.locations : [], (branch, index) => {
                   return <option key={index} value={branch}>{branch}</option>
                })}
              </Input>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label>Address</Label>
              <Input disabled={lockedAddress} className='default-input' type='text' value={newCustomer.address.physical} onChange={(e) => setNewCustomer({...newCustomer, address: {...newCustomer.address, physical: e.target.value } })} />
              {lockedAddress ? <MdRemoveCircleOutline className='remove-addy' size={24} onClick={()=> {
                setNewCustomer({...newCustomer, address: {physical: '', geo: {long: '', lat: ''}}})
                setAddressSearchResults([])
                setLockedAddress(false);
              }}/> : null}
              {!_.isEmpty(addressSearchResults) && !lockedAddress ? autoCompleteAddress() : null}
            </Col>
          </Row>
          {createError ? <Alert color='danger' className='mt-2 mb-4'>{createError}</Alert> : null}
          {createSuccess ? <Alert className='mt-2 mb-4'>{createSuccess}</Alert> : null}
        </ModalBody>
        <div className='center-wrapper'>
          <MainButton className='reset-submit-btn' text={'Create'} loading={loading} disabled={loading} clickAction={createCustomer} />
        </div>
        </Modal>
    )
  }

  return (
    <Container fluid={true}>
    <Row className='search-row'>
      <Col>
      <div>
        <img alt='decorative' className='search-icon' src={SearchIcon} />
        <Input onChange={(e) => {setSearchTerm(e.target.value)
           setCurrentPage(1)}} className='search-input' placeholder='Type a name to search...'/>
      </div>
      </Col>
      <Col className='add-user-col' xlg={2} lg={2} md={4} sm={4} xsm={4}>
      <Button className='add-user-btn' onClick={(e)=>setShowAddCustomerModal(!showAddCustomerModal)}><img alt='decorative' className='plus-icon' src={AddIcon} />ADD CUSTOMER</Button>
      </Col>
    </Row>
    <Table>
      {tableHeader()}
      {renderTableBody()}
    </Table>
    {requesting.customerList ? <LoadingTable /> : null}
    {_.isEmpty(showData()) && !requesting.customerList ? <NoResults /> : null}
    <TableEnd completeList={completeList} setDisplayLimit={setDisplayLimit} setCurrentPage={setCurrentPage}
    indexOfLastData={indexOfLastData} indexOfFirstData={indexOfFirstData} displayLimit={displayLimit} currentPage={currentPage}/>
    {showAddCustomerModal ? addCustomer() : null}
  </Container>
  );
}
