import _ from 'lodash';
import Axios from 'axios';

const pkToken = 'pk.eyJ1IjoidGlueWRpZ2l0YWwiLCJhIjoiY2tpZXlnbHF4MDR6YzM0bzNwOTVqZTFhNiJ9.XkvVyy0TDTfxcUDmqyrInA'

export const AddressLookup = async (searchText) => {

    if(_.size(searchText) >= 6) {
        const response = await Axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${searchText}.json?country=nz&fuzzyMatch=true&access_token=${pkToken}`);
        return response.data;
    }

}