import React, { useState, useEffect } from 'react';
import { useFirebase } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Input, Form, Alert, Modal, ModalBody, ModalHeader  } from 'reactstrap';
import { MainButton } from '../modules/button';
import _ from 'lodash';
import './style.css';
import Logo from "../../images/login/logo_white.png";

export const Login = () => {
  const firebase = useFirebase();
  const [loading, isLoading] = useState(false);
  const [loginDetails, setLoginDetails] = useState({email: '', password: ''});
  const [error, setError] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [resetError, setResetError] = useState(null);
  const [resetSuccess, setResetSuccess] = useState(null);

  const history = useHistory();

  const authData = useSelector(state => state.firebase.auth)

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        login();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  },);

  useEffect(() => {
    const { isLoaded, isEmpty } = authData;
    if(isLoaded && !isEmpty) {
      history.push('/dashboard')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authData])

  const login = () => {
    isLoading(true);
    setError(null);
    if(_.isEmpty(loginDetails.email) || _.isEmpty(loginDetails.password)) {
      setError('Please enter a username and password');
      isLoading(false);
    } else {
        firebase.login(loginDetails)
        .then(() => {
          isLoading(false)
         //Hook will push to dashboard
        })
        .catch(()=>{
          isLoading(false)
          setError('Incorrect username or password');
        })
    }
  }

  const resetRequest = () => {
    isLoading(true);
    setResetError(null);
    setResetSuccess(null);
    if(_.isEmpty(resetEmail)) {
      setResetError('Please enter a valid email')
      isLoading(false);
    } else {
      firebase.resetPassword(resetEmail)
      .then(() => {
        setResetSuccess('If your email has been registered you will recieve instructions soon.')
        isLoading(false);
      })
      .catch(() => {
        setResetSuccess('If your email has been registered you will recieve instructions soon.')
        isLoading(false);
      })
    }
  }

  const resetModal = () => {
    return(
      <Modal isOpen={isModalOpen} toggle={(e)=>setIsModalOpen(!isModalOpen)} centered={true}>
        <ModalHeader toggle={(e)=>setIsModalOpen(!isModalOpen)}>
          Password Reset Request
        </ModalHeader>
        <ModalBody>
          <p>Please enter your registered email to send a reset request.</p>
          <Input className='login-input' type='email' placeholder='Email' onChange={(e)=> setResetEmail(e.target.value)}/>
          {resetError ? <Alert color='danger' className='mt-2 mb-4'>{resetError}</Alert> : null}
          {resetSuccess ? <Alert className='mt-2 mb-4'>{resetSuccess}</Alert> : null}
        </ModalBody>
        <div className='center-wrapper'>
          <MainButton className='reset-submit-btn' text={'Submit'} loading={loading} disabled={loading} clickAction={resetRequest} />
        </div>
        </Modal>
    )
  }

  return (
    <Container className='login-main-container'>
        <div className='login-box'>
          <div className='login-logo-wrapper'>
            <img alt='logo' src={Logo}/>
          </div>
          <h5 className='text-center'>Onpoint Dashboard</h5>
          <Form>
            <Input className='login-input' type='email' placeholder='Email' onChange={(e)=> setLoginDetails({...loginDetails, email: e.target.value})}/>
            <Input className='login-input' type='password' placeholder='Password' onChange={(e)=> setLoginDetails({...loginDetails, password: e.target.value})}/>
            {error ? <Alert color='danger' className='mt-2 mb-4'>{error}</Alert> : null}
            <div className='center-wrapper'>
              <MainButton className={'login-btn'}  text={'Log in'} loading={loading} disabled={loading} clickAction={login}/>
            </div>
            <div className='center-wrapper forgot-password-wrapper' onClick={(e) => setIsModalOpen(!isModalOpen)}>
              <p>Forgotten Password?</p>
            </div>
          </Form>
        </div>
        {resetModal()}
    </Container>
  );
}

