import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Label, Row, Col, Input, Alert, FormText, Modal, ModalBody, ModalHeader, Button } from 'reactstrap';
import _ from 'lodash';
import { useFirebase, useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { MainButton } from '../../modules/button';
import { CarDetails } from '../newJob/jobComponents/carDetails';
import { MdDelete, MdArrowDropDown, MdPrint, MdRemoveCircle, MdSave } from 'react-icons/md';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import ImageUploader from '../../modules/react-images-upload';
import Spinner from 'reactstrap/lib/Spinner';
import PDFImg from '../../modules/react-images-upload/pdfIcon.svg';
import { LineItem } from './lineItem';
import ModalFooter from 'reactstrap/lib/ModalFooter';


export const ViewJob = () => {



  const params = useParams();
  const gstPercentage = 1.15;
  const firestore = useFirestore();
  const firebase = useFirebase();
  const { data: { user, settingsList: { settings }, userList, allJobs }, data } = useSelector(state => state.firestore);
  const [technicans, setTechnicans] = useState([])
  const [loading, setLoading] = useState(false);
  const [newJob, setNewJob] = useState(null);
  const [jobInfo, setJobInfo] = useState({ description: '', jobAmount: 0, });
  const [totalValues, setTotalValues] = useState({ gst: 0.00, total: 0.00 })
  const [createError, setCreateError] = useState(null);
  const [showStatusChangeMenu, setShowStatusChangeMenu] = useState(false);
  const [showCompleteAlert, setShowCompleteAlert] = useState(false);
  const history = useHistory();
  const [pictures, setPictures] = useState([]);
  const [imagesToRemove, setImagesToRemove] = useState([]);
  const [priceUpdates, setPriceUpdates] = useState([]);
  const [uploaderKey, setUploaderKey] = useState(0); // used to refresh uploader on state change to remove pictures.
  const [showInsuranceModal, setShowInsuranceModal] = useState(false);

  const onDrop = picture => {
    setPictures([...pictures, picture]);
  };

  useEffect(()=> {
    if(!newJob) return
    setTechnicans([..._.filter(userList, (u) => {
      if(_.includes(u.branches, newJob.branch) && u.userType.isWorkshop) return u
    })])
}, [newJob])



  useEffect(() => {
    if (newJob) {
      const { jobInformation } = newJob;
      let gstValue = 0.00;
      let total = 0.00;
      _.each(jobInformation, (job) => {
        const priceGst = job.jobAmount * gstPercentage;
        const gstOnly = priceGst - job.jobAmount;
        gstValue = gstValue + gstOnly;
        total = total + job.jobAmount;
      })
      total = total + gstValue;
      setTotalValues({ gst: gstValue, total: total })
    }
  }, [newJob])

  useEffect(() => {
    if(!user.userType.isAdministrator) {
      const fetchDoc = async () => {
        const doc = await firestore.collection('jobs').doc(params.id).get()
        setNewJob(doc)
      }
      fetchDoc()
    } else {
      setNewJob(allJobs[`${params.id}`])
    }

  }, [])

  const updateItemDescription = (value, index) => {
    const update = _.map(newJob.jobInformation, (v, i) => {
      if (i !== index) {
        return v;
      } else {
        return {
          ...v,
          description: value
        }
      }
    })
    setNewJob({ ...newJob, jobInformation: [...update] })
  }

  const updateItemValue = (value, index) => {
    const update = _.map(newJob.jobInformation, (v, i) => {
      if (i !== index) {
        return v;
      } else {
        return {
          ...v,
          jobAmount: value
        }
      }
    })
    setNewJob({ ...newJob, jobInformation: [...update] })
  }

  const renderJobItems = () => {
    return _.map(newJob.jobInformation, (item, index) => {
      return (
        <LineItem item={item} key={index} deleteJobDescription={deleteJobDescription} updateItemValue={updateItemValue} updateItemDescription={updateItemDescription}
          index={index} user={user} />
      )
    })
  }

  const renderSelectedTechs = () => {
    return _.map(newJob.techniciansName, (tech, index) => {
      return <p key={index} className='selected-tech' onClick={(e) => removeTech(tech.id, tech.name)}>{tech.name}</p>
    })
  }

  const removeTech = (id) => {
    setNewJob({
      ...newJob, technicans: [..._.filter(newJob.technicans, (tech) => { return tech !== id })], techniciansName:
        [..._.filter(newJob.techniciansName, (tech) => { return tech.id !== id })]
    })
  }

  const submitJobDescription = () => {
    const { description } = jobInfo;
    const index = _.size(newJob.jobInformation);
    if (!_.isEmpty(description)) {
      setNewJob({ ...newJob, jobInformation: [...newJob.jobInformation, { ...jobInfo, key: index }] });
      setJobInfo({ description: '', jobAmount: 0 });
    }
  }

  const deleteJobDescription = (key) => {
    setNewJob({ ...newJob, jobInformation: [..._.filter(newJob.jobInformation, (desc) => { return desc.key !== key })] });
  }

  const validation = () => {
    const { branch, jobType, insurance, policyNumber, dateOfInstall, technicans,
      jobInformation, customerDetails, carDetails } = newJob;
    if (_.isEmpty(branch) || branch === '-' || _.isEmpty(jobType) || jobType === '-') {
      setLoading(false);
      setCreateError('Please select the branch and jobtype')
      return false;
    }
    if (insurance && _.isEmpty(policyNumber)) {
      setNewJob({ ...newJob, policyNumber: '' })
      setNewJob({ ...newJob, insuranceCompany: '' })
    }
    if (dateOfInstall <= 0) {
      setLoading(false);
      setCreateError('Please include the date of install')
      return false;
    }
    if (_.isEmpty(technicans)) {
      setLoading(false);
      setCreateError('Please include at least one technican')
      return false;
    }
    if (_.isEmpty(customerDetails)) {
      setLoading(false);
      setCreateError('Please select a customer')
      return false;
    }
    if (jobType === 'Tinting - Auto' || jobType === 'Auto Glass') {
      if (_.isEmpty(carDetails)) {
        setLoading(false);
        setCreateError('Please include the vehicle details')
        return false;
      }
    }
    if (_.isEmpty(jobInformation)) {
      setLoading(false);
      setCreateError('Please include at least one job task')
      return false;
    }

    return true;

  }

  const uploadPictures = async () => {
    let files = [];
    const upload = await firebase.uploadFiles(`${params.id}/photos`, ...pictures);
    const date = moment().unix();
    for (let file of upload) {
      const download = await file.uploadTaskSnapshot.ref.getDownloadURL();
      files = [
        ...files,
        {
          name: file.uploadTaskSnapshot.metadata.name,
          url: download,
          uploadedBy: user.id,
          uploadedByName: user.fullName,
          uploadedAt: date,
        }
      ]
    }
    setPictures([]);
    setUploaderKey(uploaderKey + 1);
    return files;
  }

  const deletePhotosFromFirebase = async () => {
    for (let image of imagesToRemove) {
      await firebase.deleteFile(`${params.id}/photos/${image.name}`)
    }
    setImagesToRemove([]);
  }

  const submitJob = async (complete) => {
    setLoading(true);
    setCreateError(null);
    const isValid = validation();

    if (isValid) {
      const date = moment().unix();
      const doc = await firestore.collection('jobs').doc(params.id).get();
      let photos = [];
      const updateCust = newJob.hasOwnProperty('updateCust') ? newJob.updateCust : false;
      delete newJob.updateCust;
      if (!_.isEmpty(pictures)) {
        photos = await uploadPictures();

      }
      if (!_.isEmpty(imagesToRemove)) {
        await deletePhotosFromFirebase()
      }
      if (newJob.hasOwnProperty('photos')) {
        photos = [...photos, ...newJob.photos]
      }
      await doc.ref.update({
        ...newJob,
        status: complete ? 'awaiting payment' : newJob.status,
        audit: {
          ...newJob.audit,
          lastUpdatedByName: user.fullName,
          lastUpdatedById: user.id,
          lastUpdatedAt: date,
        },
        photos,
      })
      // update customer?
      if(updateCust) {
        const custRef = await firestore.collection('customers').doc(newJob.customerDetails.customerId).get();
        await custRef.ref.update({
          fullName: newJob.customerDetails.customerName,
          email: newJob.customerDetails.customerEmail,
          mobileContact: newJob.customerDetails.customerPhone,
        })
      }

      setLoading(false);
    }
  }

  const renderAutoInfo = () => {
    const { jobType } = newJob;
    if (jobType === 'Auto Glass') {
      return <CarDetails updateFunc={setNewJob} newJob={newJob} update={true} user={user} />
    }
    if (jobType === 'Tinting - Auto') {
      return <CarDetails updateFunc={setNewJob} newJob={newJob} update={true} user={user} />
    }
  }

  const completeModal = () => {
    return (
      <Modal isOpen={showCompleteAlert} toggle={(e) => setShowCompleteAlert(!showCompleteAlert)} centered={true}>
        <ModalHeader toggle={(e) => setShowCompleteAlert(!showCompleteAlert)}>
          Warning!
        </ModalHeader>
        <ModalBody>
          <p>Marking this job as <strong>invoiced</strong> will prohibit you from editing this job in the future. Continue?</p>
        </ModalBody>
        <div className='center-wrapper'>
          <MainButton className='reset-submit-btn' text={'Yes, update'} clickAction={completeJob} />
        </div>
      </Modal>
    )
  }

  const completeJob = () => {
    const isValid = validation();

    if (isValid) {
      //setShowCompleteAlert(!showCompleteAlert)
      setNewJob({ ...newJob, status: 'awaiting payment' })
      submitJob(true);
    }
  }

  const renderStatusChangeMenu = () => {
    if (showStatusChangeMenu) {
      return (<div className='user-menu-wrapper' onMouseLeave={(e) => setShowStatusChangeMenu(!showStatusChangeMenu)}>
        <p onClick={(e) => setNewJob({ ...newJob, status: 'in-progress' })}>In-progress</p>
        <p onClick={(e) => setNewJob({ ...newJob, status: 'quote' })}>Quoted</p>
        <p onClick={(e) => setNewJob({ ...newJob, status: 'to be quoted' })}>To be Quoted</p>
        <p onClick={(e) => setNewJob({ ...newJob, status: 'complete' })}>Complete</p>
        {user.userType.isAdministrator ? <p onClick={() => setNewJob({ ...newJob, status: 'on-hold' })}>On-Hold</p> : null}
        {user.userType.isAdministrator ? <p onClick={() => setNewJob({ ...newJob, status: 'cancelled' })}>Cancelled</p> : null}
        {user.userType.isAdministrator ? <p onClick={() => completeJob()}>Invoiced</p> : null}
      </div>)
    }
  }

  const renderJobStatusBadge = (status) => {
    if (status === 'in-progress') {
      return <div className='job-status-wrapper'><p className='job-status-badge yellow'>in-progress</p>
        <MdArrowDropDown size={28} color='black' className='status-icon' onClick={(e) => setShowStatusChangeMenu(!showStatusChangeMenu)} />
        {renderStatusChangeMenu()}
      </div>
    }
    if (status === 'quote') {
      return <div className='job-status-wrapper'><p className='job-status-badge purple'>Quoted</p>
        <MdArrowDropDown size={28} color='black' className='status-icon' onClick={(e) => setShowStatusChangeMenu(!showStatusChangeMenu)} />
        {renderStatusChangeMenu()}
      </div>
    }
    if (status === 'to be quoted') {
      return <div className='job-status-wrapper'><p className='job-status-badge purple'>To be Quoted</p>
        <MdArrowDropDown size={28} color='black' className='status-icon' onClick={(e) => setShowStatusChangeMenu(!showStatusChangeMenu)} />
        {renderStatusChangeMenu()}
      </div>
    }
    if (status === 'complete') {
      return <div className='job-status-wrapper'><p className='job-status-badge red'>Complete</p>
        <MdArrowDropDown size={28} color='black' className='status-icon' onClick={(e) => setShowStatusChangeMenu(!showStatusChangeMenu)} />
        {renderStatusChangeMenu()}
      </div>
    }
    if (status === 'awaiting payment') {
      return <div className='job-status-wrapper'><p className='job-status-badge green'>Invoiced</p>
        <MdArrowDropDown size={28} color='black' className='status-icon' onClick={(e) => setShowStatusChangeMenu(!showStatusChangeMenu)} />
        {user.userType.isAdministrator ? renderStatusChangeMenu() : null}
      </div>
    }
    if (status === 'on-hold') {
      return <div className='job-status-wrapper'><p className='job-status-badge gray'>On-Hold</p>
        <MdArrowDropDown size={28} color='black' className='status-icon' onClick={(e) => setShowStatusChangeMenu(!showStatusChangeMenu)} />
        {user.userType.isAdministrator ? renderStatusChangeMenu() : null}
      </div>
    }
    if (status === 'cancelled') {
      return <div className='job-status-wrapper'><p className='job-status-badge black'>Cancelled</p>
        <MdArrowDropDown size={28} color='black' className='status-icon' onClick={(e) => setShowStatusChangeMenu(!showStatusChangeMenu)} />
        {user.userType.isAdministrator ? renderStatusChangeMenu() : null}
      </div>
    }

  }
  const printView = () => {
    history.push('/print', { job: { ...newJob } })
  }

  const removeImage = (removeName) => {
    let filesToRemove = [];
    setNewJob({
      ...newJob, photos: [..._.filter(newJob.photos, (photo) => {
        if (removeName === photo.name) {
          filesToRemove = [
            ...filesToRemove,
            { ...photo }
          ]
        }
        return removeName !== photo.name;
      })]
    })

    setImagesToRemove([...imagesToRemove, ...filesToRemove])
  }

  const renderUploadedPhotos = () => {
    return _.map(newJob.photos, (photo, index) => {
      let isPdf = photo.name.substring(photo.name.length, photo.name.length - 3)
      isPdf = isPdf === 'pdf' ? true : false;
      return (
        <div key={index} className='photo-preview'>
          <MdRemoveCircle className='photo-delete-icon' size={24} onClick={(e) => removeImage(photo.name)} />
          <div className='img-wrapper'>
            <img src={isPdf ? PDFImg : photo.url} onClick={(e) => window.open(photo.url, '_blank')} />
          </div>

          <FormText>{photo.name}</FormText>
          <FormText>Uploaded by: {photo.uploadedByName}</FormText>
          <FormText>Time: {moment.unix(photo.uploadedAt).format('DD/MM/YYYY hh:mm:ss')}</FormText>
        </div>
      )
    })
  }

  const addTechnician = async (id) => {
    if (id === '-') return;
    const techn = await firestore.collection('users').doc(id).get();
    const techName = techn.data().fullName;
    if (!_.includes(newJob.technicans, id) && id !== '-') {
      setNewJob({ ...newJob, technicans: [...newJob.technicans, id], techniciansName: [...newJob.techniciansName, { name: techName, id: id }] })
    }
  }

  const renderTechOptions = () => {
    if (newJob.branch === '-' && (!technicans || _.isEmpty(technicans))) {
      return <option value='-'>Please select a branch first</option>
    }
    else if (newJob.branch !== '-' && _.isEmpty(technicans)) {
      return <option value='-'>No techs assigned to this branch</option>
    }
    else {
      return _.map(technicans, (tech, index) => {
        return <option key={index} value={tech.id}>{tech.fullName}</option>
      })
    }
  }

  const renderJobLocation = (job) => {
    if (job.isOnsite) {
      return <p className='commercial-p'>ONSITE</p>
    } else if (!job.hasOwnProperty('isOnsite')) {
      return null
    } else if (!job.isOnsite) {
      return null
    }
  }

  const setJobLocation = (type) => {
    if (type === 'Supply only') {
      setNewJob({ ...newJob, isOnsite: false, supplyOnly: true })
    } else {
      setNewJob({ ...newJob, isOnsite: type === 'true' ? true : false, supplyOnly: false })
    }
  }

  const setJobLocationSelect = () => {
    if (newJob.supplyOnly) {
      return 'Supply only'
    } else {
      return newJob.isOnsite
    }
  }

  const renderJobNumber = () => {
    return newJob.jobId ? <p className='job-status-badge job-number mb-3'>Job: {newJob.jobId}</p> : <Spinner />
  }

  const toggleInsuranceModal = (value) => {
    if (value) {
      setShowInsuranceModal(!showInsuranceModal)
    } else {
      setNewJob({ ...newJob, insurance: false })
    }
  }

  const renderInsuranceModal = () => {
    return (
      <Modal isOpen={showInsuranceModal} toggle={() => setShowInsuranceModal(!showInsuranceModal)} centered={true}>
        <ModalHeader>
          Import?
        </ModalHeader>
        <ModalBody>
          <p>Would you like to import insurance details from customers details?</p>
        </ModalBody>
        <ModalFooter className='modal-btn-wrapper'>
          <Button color='success' onClick={() => updateInsuranceInfoFromClient()}>Yes, Import</Button>
          <Button color='info' onClick={() => {
            setShowInsuranceModal(!showInsuranceModal)
            setNewJob({ ...newJob, insurance: true})
          }}>No, I'll enter it manually</Button>
        </ModalFooter>
      </Modal>
    )
  }

  const updateInsuranceInfoFromClient = async () => {
    const doc = await firestore.collection('customers').doc(newJob.customerDetails.customerId).get();
    const data = doc.data();
    if(data.hasOwnProperty('insuranceDetails')) {
      const { insuranceCompany, policyNumber } = data.insuranceDetails;
      setNewJob({ ...newJob, insurance: true, insuranceCompany: insuranceCompany, policyNumber: policyNumber})
      setShowInsuranceModal(!showInsuranceModal)
    } else {
      setShowInsuranceModal(!showInsuranceModal)
    }
    //setNewJob({ ...newJob, insurance: e.target.value === 'true' ? true : false })
  }
 
  if (!newJob || !user) return null
  return (
    <Container fluid={true}>
      <Container className='new-job-wrapper' type='print'>
        {renderJobNumber()}
        <div className='job-update-header'>

          <div><p className='main-title'>View/Update Job</p>{renderJobLocation(newJob)}</div>
          {renderJobStatusBadge(newJob.status)}

        </div>
        {user.userType.isAdministrator ?
          <div className='add-button-wrapper'>
            <MainButton text={'Print'} icon={<MdPrint size={18} className='mr-2' />} className={'add-button'} clickAction={printView} />
          </div> : null}
        <Row>
          <Col>
            <Label>Branch</Label>
            <Input type='select' className='job-input' disabled={false} value={newJob.branch} onChange={(e) => setNewJob({ ...newJob, branch: e.target.value })}>
              <option value='-'>Select</option>
              {_.map(settings ? settings.locations : [], (branch, index) => {
                return <option key={index} value={branch}>{branch}</option>
              })}
            </Input>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Job Type</Label>
            <Input type='select' className='job-input' value={newJob.jobType} onChange={(e) => setNewJob({ ...newJob, jobType: e.target.value })}>
              <option value='-'>Select</option>
              <option value='Auto Glass'>Auto Glass</option>
              <option value='Tinting - Auto'>Tinting - Auto</option>
              <option value='Tinting - Home'>Tinting - Home</option>
              <option value='Flatglass'>Flatglass</option>
            </Input>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Date of installation</Label>
            <Input type='date' className='job-input' onChange={(e) => setNewJob({ ...newJob, dateOfInstall: moment(e.target.value).unix() })} value={moment.unix(newJob.dateOfInstall).format('yyyy-MM-DD')} />
          </Col>
          <Col>
            <Label>Technician(s)</Label>
            <Input type='select' className='job-input' onChange={(e) => addTechnician(e.target.value)}>
              <option value='-'>Select</option>
              {renderTechOptions()}
            </Input>
            {!_.isEmpty(newJob.technicans) ? <div>{renderSelectedTechs()}</div> : null}
            {!_.isEmpty(newJob.technicans) ? <FormText className='text-center'>Click tech name to remove</FormText> : null}
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Workshop / Onsite / Supply</Label>
            <Input type='select' value={setJobLocationSelect()} className='job-input' onChange={(e) => setJobLocation(e.target.value)}  >
              <option value={false}>Workshop</option>
              <option value={true}>Onsite</option>
              <option value={'Supply only'}>Supply only</option>
            </Input>
          </Col>
        </Row>
        <p className='subtitle'>Customer Details</p>
        <Row>
          <Col>
            <Label>Name</Label>
            <Input className='job-input' value={newJob.customerDetails.customerName}
            onChange={(e)=> setNewJob({ ...newJob, customerDetails: {...newJob.customerDetails, customerName: e.target.value}, updateCust: true })}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Address</Label>
            <Input className='job-input' disabled={true} value={newJob.customerDetails.customerAddress} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>City / town</Label>
            <Input className='job-input' value={newJob.customerDetails.customerLocation} disabled={true}/>
           
          </Col>
          <Col>
            <Label>Phone</Label>
            <Input className='job-input' value={newJob.customerDetails.customerPhone} 
            onChange={(e)=> setNewJob({ ...newJob, customerDetails: {...newJob.customerDetails, customerPhone: e.target.value}, updateCust: true })}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Email address</Label>
            <Input className='job-input' value={newJob.customerDetails.customerEmail} 
            onChange={(e)=> setNewJob({ ...newJob, customerDetails: {...newJob.customerDetails, customerEmail: e.target.value}, updateCust: true })}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Insurance</Label>
            <Input type='select' className='job-input' value={newJob.insurance ? true : false} onChange={(e) => toggleInsuranceModal(e.target.value === 'true' ? true : false)} >
              <option value={false}>No</option>
              <option value={true}>Yes</option>
            </Input>
          </Col>
          {newJob && newJob.insurance ?
            <Col>
              <Label>Claim / Policy #</Label>
              <Input type='text' className='job-input' value={newJob.policyNumber} onChange={(e) => setNewJob({ ...newJob, policyNumber: e.target.value })} />
              <Label>Insurance company</Label>
              <Input type='text' className='job-input' value={newJob.insuranceCompany} onChange={(e) => setNewJob({ ...newJob, insuranceCompany: e.target.value })} />
            </Col> : null}
        </Row>
        {renderAutoInfo()}
        <Row className='mb-3 border-bottom'>
          <Col lg={9} md={9} sm={8}>
            <Label>Job Description</Label>
          </Col>
          <Col>
            <Label>Amount (exc GST)</Label>
          </Col>
        </Row>
        {renderJobItems()}
        {user.userType.isAdministrator ? <Row className='mt-3'>
          <Col lg={9} md={9} sm={8}>
            <Input className='job-input' placeholder='Add job description' value={jobInfo.description} onChange={(e) => setJobInfo({ ...jobInfo, description: e.target.value })} />
          </Col>
          <Col>
            <Input type='number' className='job-input' placeholder='0.00' value={jobInfo.jobAmount} onChange={(e) => setJobInfo({ ...jobInfo, jobAmount: parseFloat(e.target.value) })} />
          </Col>
        </Row> : null}
        {user.userType.isAdministrator ?
          <div className='add-button-wrapper'>
            <MainButton text={'+ Add'} className={'add-button'} clickAction={submitJobDescription} />
          </div> : null}
        <div className='flex-end-wrapper border-top'>
          <p>GST   ${totalValues.gst.toFixed(2)}</p>
        </div>
        <div className='flex-end-wrapper bottom border-bottom'>
          <p>Total   ${totalValues.total.toFixed(2)}</p>
        </div>
        <div className='mt-2'>
          <Label>Installer notes</Label>
          <FormText>These notes will not be printed</FormText>
          <Input className='job-input' type='textarea' value={newJob.installerNotes} onChange={(e) => setNewJob({ ...newJob, installerNotes: e.target.value })} />
        </div>
        <div className='mt-2'>
          <Label>Photos</Label>
          <div className='photos-wrapper'>
            {renderUploadedPhotos()}
          </div>
        </div>
        <div>
          <ImageUploader
            key={uploaderKey}
            withIcon={true}
            buttonText="Choose images"
            onChange={onDrop}
            imgExtension={[".jpg", ".gif", ".png", ".gif", ".pdf"]}
            maxFileSize={5242880}
            withPreview={_.isEmpty(pictures) ? false : true}
          />
        </div>
        <div>
          <MainButton text={'Update'} className={'submit-button'} loading={loading} clickAction={submitJob} />
        </div>
        {createError ? <Alert>{createError}</Alert> : null}
        {renderInsuranceModal()}
      </Container>

    </Container>
  );
}
