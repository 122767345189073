import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import Logo from '../../../../../images/login/logo_white.png';


// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        fontSize: 14,
    },
    section: {
        margin: 10,
        padding: 10,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    sectionEnd: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        alignContent: 'flex-end',
        marginRight: 40,
    },
    pageBorder: {
        marginLeft: 10,
        marginRight: 10,
        borderBottom: 1,
        borderStyle: 'solid',
        borderBottomColor: 'black',
        flexDirection: 'row',
        fontSize: 14,
    },
    sectionHeaderTwo: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        alignContent: 'flex-end',
    },
    image: {
        height: 70,
        width: 170,
    },
    textTitle: {
        marginBottom: 5,
        marginTop: 5,
        fontSize: 16,
        fontWeight: 'bold'
    },
    descriptionRow: {
        flexGrow: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginRight: 50,
    }
});

const stylesTwo = StyleSheet.create({
    contentsWrapper: {
        padding: 10,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        borderStyle: 'solid',
        borderBottomColor: 'black',
        borderBottom: 1,
        fontSize: 14,
    },
    content: {
        minWidth: '25%'
    },
    image: {
        height: 70,
        width: 190,
    },
    textTitle: {
        marginBottom: 5,
        marginTop: 5,
        fontSize: 16,
        fontWeight: 'bold',
        textDecoration: 'underline'
    },
})



export const MyDocument = () => {
    const { state: { job } } = useLocation();
    const gstPercentage = 1.15;
    const [totalValues, setTotalValues] = useState({ gst: 0.00, total: 0.00 })

    useEffect(() => {
        if (job) {
            const { jobInformation } = job;
            let gstValue = 0.00;
            let total = 0.00;
            _.each(jobInformation, (job) => {
                const priceGst = job.jobAmount * gstPercentage;
                const gstOnly = priceGst - job.jobAmount;
                gstValue = gstValue + gstOnly;
                total = total + job.jobAmount;
            })
            total = total + gstValue;
            setTotalValues({ gst: gstValue.toFixed(2), total: total.toFixed(2) })
        }
    }, [job])

    const renderTechs = () => {

        return _.map(job.techniciansName, (tech, index) => {
            return <Text key={index}>{tech.name}</Text>
        })
    }
    const { textTitle, contentsWrapper, content } = stylesTwo;
    return (
        <PDFViewer className='pdf-wrapper'>
            <Document>
                <Page size="A4" >
                    <View style={contentsWrapper}>
                        <View >
                            <Image style={stylesTwo.image} src={Logo} />
                        </View>
                        <View >
                            <Text>Job ID: {job.jobId}</Text>
                        </View>
                    </View>
                    <View style={contentsWrapper}>
                        <View style={content}>
                            <Text style={textTitle} >Date</Text>
                            <Text>{moment.unix(job.dateOfInstall).format('DD/MM/YYYY')}</Text>
                        </View>
                        <View style={content}>
                            <Text style={textTitle}>Technician(s)</Text>
                            {renderTechs()}
                        </View>
                    </View>
                    <View style={contentsWrapper}>
                        <View style={content}>
                            <Text style={textTitle}>Job</Text>
                            <Text>{job.jobType}</Text>
                        </View>
                        {job.insurance ? <View style={content}>
                            <Text style={textTitle}>Claim #</Text>
                            <Text>{job.policyNumber}</Text>
                            <Text style={textTitle}>Insurance Company</Text>
                            <Text>{job.insuranceCompany}</Text>
                        </View> : null}
                    </View>
                    <View style={contentsWrapper}>
                        <View style={content}>
                            <Text style={textTitle}>Customer</Text>
                            <Text>{job.customerDetails.customerName}</Text>
                            <Text>{job.customerDetails.customerAddress}</Text>
                        </View>
                        <View style={content}>
                            <Text style={textTitle}>Phone</Text>
                            <Text>{job.customerDetails.customerPhone}</Text>
                        </View>
                        <View style={content}>
                            <Text style={textTitle}>Email</Text>
                            <Text>{job.customerDetails.customerEmail}</Text>
                        </View>
                    </View>
                    {job.jobType === 'Auto Glass' || job.jobType === 'Tinting - Auto' ?
                        <View style={{ marginTop: 10 }} >
                            <Text style={{ ...textTitle, paddingLeft: 10, }}>Vehicle Details</Text>
                            <View style={contentsWrapper}>

                                <View style={content}>

                                    <Text>Make: {job.carDetails.make}</Text>
                                    <Text>Model: {job.carDetails.model}</Text>

                                </View>
                                <View style={content}>
                                    <Text></Text>
                                    <Text>Rego: {job.carDetails.rego}</Text>
                                    <Text>Year: {job.carDetails.year}</Text>
                                </View>
                            </View>
                        </View> : null}
                    <View style={contentsWrapper}>
                        <View style={content}>
                            <Text style={textTitle}>Job Description(s)</Text>
                            {_.map(job.jobInformation, (info, index) => {
                                return (
                                    <View key={index} style={contentsWrapper}>
                                        <View style={content}>
                                            <Text>{info.description}</Text>
                                        </View>
                                        <View style={content}>
                                            <Text>${info.jobAmount.toFixed(2)}</Text>
                                        </View>
                                    </View>
                                )
                            })}
                        </View>
                    </View>
                    <View style={{ width: '100%', paddingTop: 10, paddingRight: 10, width: '100%', fontSize: 15, }}>
                        <View style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end',
                            alignContent: 'flex-end',
                        }}>
                            <Text>GST: ${totalValues.gst}</Text>
                            <Text>Total: ${totalValues.total}</Text>
                        </View>
                    </View>
                </Page>
            </Document></PDFViewer>
    )
}

/*
 <PDFViewer className='pdf-wrapper'> <Document>
            <Page size="A4" >
                <View style={styles.page}>
                    <View style={styles.section}>
                        <Image style={styles.image} src={Logo} />
                    </View>
                    <View style={styles.sectionHeaderTwo}>
                        <Text>Job ID: {job.jobId}</Text>
                    </View>
                </View>
                <View style={styles.pageBorder}>
                    <View style={styles.section}>
                        <Text style={styles.textTitle} >Date</Text>
                        <Text>{moment.unix(job.dateOfInstall).format('DD/MM/YYYY')}</Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.textTitle}>Technician(s)</Text>
                        {renderTechs()}
                    </View>
                </View>
                <View style={styles.pageBorder}>
                    <View style={styles.section}>
                        <Text style={styles.textTitle}>Job</Text>
                        <Text>{job.jobType}</Text>
                    </View>
                    {job.insurance ? <View style={styles.section}>
                        <Text style={styles.textTitle}>Claim #</Text>
                        <Text>{job.policyNumber}</Text>
                        <Text style={styles.textTitle}>Insurance Company</Text>
                        <Text>{job.insuranceCompany}</Text>
                    </View> : null}
                </View>
                <View style={styles.pageBorder}>
                    <View style={styles.section}>
                        <Text style={styles.textTitle}>Customer</Text>
                        <Text>{job.customerDetails.customerName}</Text>
                        <Text>{job.customerDetails.customerAddress}</Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.textTitle}>Phone</Text>
                        <Text>{job.customerDetails.customerPhone}</Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.textTitle}>Email</Text>
                        <Text>{job.customerDetails.customerEmail}</Text>
                    </View>

                </View>
                {job.jobType === 'Auto Glass' || job.jobType === 'Tinting - Auto' ? <View style={styles.pageBorder}>
                    <View style={styles.section}>
                        <Text style={styles.textTitle}>Vehicle Details</Text>
                        <Text>Make: {job.carDetails.make}</Text>
                        <Text>Model: {job.carDetails.model}</Text>
                    </View>
                    <View style={styles.section}>
                        <Text></Text>
                        <Text>Rego: {job.carDetails.rego}</Text>
                        <Text>Year: {job.carDetails.year}</Text>
                    </View>
                </View> : null}
                <View style={styles.pageBorder}>
                    <View style={styles.section}>
                        <Text style={styles.textTitle}>Job Description(s)</Text>
                        {_.map(job.jobInformation, (info, index) => {
                            return (
                                <View key={index} style={styles.descriptionRow}>
                                    <View>
                                        <Text>{info.description}</Text>
                                    </View>
                                    <View>
                                        <Text>${info.jobAmount.toFixed(2)}</Text>
                                    </View>
                                </View>
                            )
                        })}
                        <View style={styles.sectionEnd}>
                            <Text>GST: ${totalValues.gst}</Text>
                            <Text>Total: ${totalValues.total}</Text>
                        </View>
                    </View>
                </View>

            </Page>
        </Document></PDFViewer>
*/
